import {NTR} from "../../name-to-request";
import RequestController from "../../static/request/request";
import StatisticNameModel from "./model";
import StatisticNameRequest from "./request";

export default class NameTypeData {
  constructor({api, isShowError = true}) {
    this._api = api;
    this._isShowError = isShowError;

    this._performance = null;
  }

  getData(names) {
    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: StatisticNameRequest.get,
        parameter: {names},
        checkRequiredParameter: {names},
        requiredResponseParameters: [NTR.PEOPLE_NAME_TYPE],
        parseResponse: StatisticNameModel.parse,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить статистику по именам.`),
      }).send();
    });
  }
}
