/**
 * Функция, проверяющая, входит ли переданный домен в список часто встречающихся доменов
 * @param {string} доменное имя
 * @returns {boolean} булево значение
 */

import {commonDomains} from "../../const/common-domains";

export const checkCommonDomain = (domain = ``) => {
  if (!domain) {
    return false;
  }

  if (typeof domain === `object`) {
    return false;
  }

  return commonDomains.includes(domain);
};
