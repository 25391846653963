import {EmailStatus} from "../const";

const ColorTextEmailPerStatus = {
  [EmailStatus.OK]: `green`,
  [EmailStatus.NOT_EXIST]: `red`,
  [EmailStatus.FULL]: `darkorange`,
};

export const getEmailMarkup = ({mail = ``, status = EmailStatus.UNDEFINED}) => {
  if (!status || status === EmailStatus.UNDEFINED) {
    return mail;
  }

  return `<span style="color: ${ColorTextEmailPerStatus[status]}">${mail}</span>`;
};
