import PeopleItemComponent from "../../component/people-item/people-item";
import {render, RenderPosition} from "../../utils/render";
import PeopleController from "../people";

export default class ContactPeopleController extends PeopleController {
  constructor({container, contactPeople, peopleData, isBlocked, checkIsBlocked, isMultiWrapInput = true, modeRenderEmail, modeRenderPhone, onAddContactPeople, onEditContactPeople, onReplaceContactPeople}) {
    super();

    this._container = container;
    this._people = contactPeople;
    this._peopleData = peopleData;
    this._isBlocked = isBlocked;
    this._checkIsBlocked = checkIsBlocked;
    this._onAddPeopleHandler2 = onAddContactPeople;
    this._onEditPeopleHandler2 = onEditContactPeople;
    this._onReplacePeopleHandler2 = onReplaceContactPeople;

    this._isMultiWrapInput = isMultiWrapInput;
    this._modeRenderEmail = modeRenderEmail;
    this._modeRenderPhone = modeRenderPhone;

    this._addPopupTitle = `Добавление контактного лица`;

    this._isSurnameWrapRequired = true;
    this._isLastnameWrapRequired = true;
    this._isMiddlenameWrapRequired = true;
    this._isPhoneWrapRequired = true;
    this._isEmailWrapRequired = true;
  }

  addContactPeople() {
    this._addPeople2();
  }

  render() {
    const itemComponent = new PeopleItemComponent({
      people: this._people,
      title: `Контактное лицо`,
      isDisabled: this._isBlocked,
    });

    this._renderReplaceButton(itemComponent.getElement());
    this._renderEditButton(itemComponent.getElement());

    render(this._container, itemComponent.getElement(), RenderPosition.BEFOREEND);
  }

  _onEditItemButtonClickHandler() {
    this._editPeople2();
  }
}
