export const DEFAULT_CONTACT = {
  mode: `add`,
  peopleId: ``,
  surname: ``,
  lastname: ``,
  middlename: ``,
  phones: [],
  emails: [],
  type: `контактное лицо`,
};
