import MultiInputsController from ".";
import EmailInputComponent from "../../component/input/email-input/email-input";
import {ModeRenderWrap, MultiWrapInputRequiredMode} from "../../const";
import {isEmptyArray} from "../../utils/array/is-empty-array/is-empty-array";
import {render, RenderPosition} from "../../utils/render";

export default class EmailMultiInputsController extends MultiInputsController {
  constructor({emails = [], isShowAddButton = false, inputMode = `passive`, isRequired = false, requiredMode = MultiWrapInputRequiredMode.NONE, modeRender = ModeRenderWrap.ALL, isDeleteButton = false, onChangeData = () => {}}) {
    super();

    this._emails = emails;
    this._isShowAddButton = isShowAddButton;
    this._addButtonTitle = `Добавить еще email`;
    this._label = `Email`;
    this._errorValidateText = `Заполните поле электронной почты`;


    this._isRequired = isRequired;
    this._inputMode = inputMode;
    this._requiredMode = requiredMode;
    this._modeRender = modeRender;
    this._isDeleteButton = isDeleteButton;
    this._onChangeData = onChangeData;
  }

  _renderInputs() {
    if (isEmptyArray(this._emails)) {
      const emailInputComponent = this._getEmailInput({
        label: this._label,
      });
      emailInputComponent.setInputHandler(this._onInputHandler);
      emailInputComponent.setDeleteButtonClickHandler(this._onDeleteHandler);

      this._inputComponents.push(emailInputComponent);

      render(this._wrapComponent.getElement(), emailInputComponent.getElement(), RenderPosition.BEFOREEND);
      return;
    }

    if (this._modeRender === ModeRenderWrap.ONE) {
      const emailInputComponent = this._getEmailInput({
        label: this._label,
        value: this._emails[0].mail,
        inputId: this._emails[0].mailId || ``,
        isDeleteButton: this._isDeleteButton,
      });
      emailInputComponent.setInputHandler(this._onInputHandler);
      emailInputComponent.setDeleteButtonClickHandler(this._onDeleteHandler);
      this._inputComponents.push(emailInputComponent);
      render(this._wrapComponent.getElement(), emailInputComponent.getElement(), RenderPosition.BEFOREEND);
      return;
    }

    this._emails.forEach((email, i) => {
      const emailInputComponent = this._getEmailInput({
        label: `${this._label} #${i + 1}`,
        value: email.mail,
        inputId: email.mailId || ``,
        isDeleteButton: this._isDeleteButton,
      });
      emailInputComponent.setInputHandler(this._onInputHandler);
      emailInputComponent.setDeleteButtonClickHandler(this._onDeleteHandler);


      this._inputComponents.push(emailInputComponent);
      render(this._wrapComponent.getElement(), emailInputComponent.getElement(), RenderPosition.BEFOREEND);
    });

    // обработчик на инпут
  }

  _renderEmptyInput() {
    const emailInputComponent = this._getEmailInput({
      label: `Еще email`,
      isDeleteButton: this._isDeleteButton,
    });
    emailInputComponent.setInputHandler(this._onInputHandler);
    emailInputComponent.setDeleteButtonClickHandler(this._onDeleteHandler);

    this._inputComponents.push(emailInputComponent);
    render(this._wrapComponent.getElement(), emailInputComponent.getElement(), RenderPosition.BEFOREEND);
  }

  _getEmailInput({label, value = ``, inputId, isDeleteButton = false}) {
    const input = new EmailInputComponent({
      label,
      isRequired: this._isRequired,
      value,
      inputId,
      isDeleteButton,
      mode: this._isRequired ? `active` : `passive`,
    });

    return input;
  }
}
