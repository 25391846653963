import TrashSvgComponent from "../../component/svg/trash";
import {ButtonTitle} from "../../const";
import {render, RenderPosition} from "../../utils/render";
import ButtonController from "../button";

export default class TrashButtonController extends ButtonController {
  constructor(parameters) {
    super(parameters);

    this._className = `master__btn master__btn--deleteSvg`;
    this._size = parameters.size || `medium`;
    this._prompt = parameters.prompt || ButtonTitle.DELETE;
    this._style = parameters.style || ``;
    this._styleMode = parameters.styleMode || ``;
  }

  render() {
    super.render();

    const svgComponent = new TrashSvgComponent();
    render(this._button.getElement(), svgComponent.getElement(), RenderPosition.BEFOREEND);
  }
}
