import {stringRandom} from '../../../utils/common.js';
import AbstractComponent from '../../abstract-component';


const getRadioInputsMarkup = (className, radioName, options = [], isUseRandomId = true) => {
  return options.map((option) => {
    const {label, inputId, isCheck = false} = option;
    const radioId = isUseRandomId ? stringRandom(6) : inputId;

    return (
      `<input class="${className}__checkbox" type="radio" name="${radioName}" value="${label}" id="${radioId}" ${isCheck ? `checked` : ``}>
      <label class="${className}__checkLabel" for="${radioId}">${label}</label>`
    );
  }).join(`\n`);
};

const createRadioInputTemplate = (className, name, options, isUseRandomId) => {
  const radioName = (name === ``) ? stringRandom(8) : name;
  const optionsMarkup = getRadioInputsMarkup(className, radioName, options, isUseRandomId);

  return (
    `<div class="${className}__checkWrap">
      ${optionsMarkup}
    </div>`
  );
};

export default class RadioInputComponent extends AbstractComponent {
  constructor({className = `dropDownList`, name, options, isUseRandomId = true}) {
    super();

    this._className = className;
    this._name = name;
    this._options = options;
    this._isUseRandomId = isUseRandomId;
  }

  getTemplate() {
    return createRadioInputTemplate(this._className, this._name, this._options, this._isUseRandomId);
  }

  setInputHandler(handler) {
    const optionElements = this.getElement().querySelectorAll(`input[type="radio"]`);

    optionElements.forEach((element) => {
      element.addEventListener(`input`, () => {
        return this._isUseRandomId ? handler(element.value) : handler(element.id);
      });
    });
  }
}
