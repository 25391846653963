import AbstractComponent from '../abstract-component.js';
import replaceSvg from './../../../svg/replace.svg';

const createReplaceSvgTemplate = () => {
  return (
    `<svg aria-hidden="true" width="25" height="30">
      <use href="${replaceSvg}"/>
    </svg>`
  );
};

export default class ReplaceSvgComponent extends AbstractComponent {
  getTemplate() {
    return createReplaceSvgTemplate();
  }
}
