import {NTR} from "../../name-to-request";

export default class NameTypeRequest {
  static get({names}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PEOPLE_NAME_TYPE]: names.join(`,`)
      }
    };
  }
}
