import {isEmptyArray} from "../../utils/array/is-empty-array/is-empty-array";
import {getListTextPosition} from "../../utils/common";
import {getEmailMarkup} from "../../utils/email";
import {getFullNameText} from "../../utils/get-full-name-text/get-full-name-text";
import AbstractComponent from "../abstract-component";


const getAliasMarkup = (aliases = [], isShowMiddlename) => {
  if (isEmptyArray(aliases)) {
    return ``;
  }

  return aliases
    .map((alias) => {
      return (
        `${alias.language.toUpperCase()}: ${getFullNameText(alias, isShowMiddlename)}<br>`
      );
    })
    .join(`\n`);
};

const getPositionsMarkup = (positions = []) => {
  if (isEmptyArray(positions)) {
    return ``;
  }

  return `${getListTextPosition(positions)}<br>`;
};

const getEmailsMarkup = (emails = [], isShowEmail) => {
  if (isEmptyArray(emails) || !isShowEmail) {
    return ``;
  }

  const texts = emails
  .filter((email) => !!email.mail)
  .map((email) => getEmailMarkup(email));

  return `почта&nbsp;${texts.join(`, `)}<br>`;
};

const getPhoneMarkup = (phones = [], isShowPhone) => {
  if (isEmptyArray(phones) || !isShowPhone) {
    return ``;
  }

  return `телефон&nbsp;${phones.map((phone) => phone.phone).join(`, `)}<br>`;
};

const getUserFieldMarkup = (peopleData) => {
  const userFieldText = Object.keys(peopleData)
    .filter((key) => key.indexOf(`UF`) === 0)
    .map((key) => peopleData[key])
    .join(`, `);

  if (!userFieldText) {
    return ``;
  }

  return `<p class="master__peopleText"><i>${userFieldText}</i></p>`;
};

const createPeopleItemTemplate = (people, title, isDisabled, isShowMiddlename, isShowEmail, isShowPhone) => {
  const {positions = [], phones, emails, aliases} = people;

  const nameText = getFullNameText(people, isShowMiddlename);

  const aliasMarkup = getAliasMarkup(aliases, isShowMiddlename);
  const positionMarkup = getPositionsMarkup(positions);
  const phoneMarkup = getPhoneMarkup(phones, isShowPhone);
  const emailMarkup = getEmailsMarkup(emails, isShowEmail);
  const userFieldsMarkup = getUserFieldMarkup(people);

  return (
    `<li class="master__tile" data-disabled="${isDisabled}">
      <p class="master__peopleText">
        ${title ? `${title}:` : ``} <b>${nameText}</b><br>
        ${aliasMarkup}
        ${positionMarkup}
        ${phoneMarkup}
        ${emailMarkup}
        ${userFieldsMarkup}
      </p>
    </li>`
  );
};

export default class PeopleItemComponent extends AbstractComponent {
  constructor({people, title = ``, isDisabled = false, isShowMiddlename = true, isShowEmail = true, isShowPhone = true}) {
    super();

    this._people = people;
    this._title = title;
    this._isDisabled = isDisabled;
    this._isShowMiddlename = isShowMiddlename;
    this._isShowEmail = isShowEmail;
    this._isShowPhone = isShowPhone;
  }

  getTemplate() {
    return createPeopleItemTemplate(this._people, this._title, this._isDisabled, this._isShowMiddlename, this._isShowEmail, this._isShowPhone);
  }
}
