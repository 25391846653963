import {isEmpty} from "../../utils/is-empty/is-empty";

const parseTeams = (team, typeSearch = `other`) => {
  if (isEmpty(team)) {
    return [];
  }

  return Object.keys(team)
  .map((teamId) => {
    return {
      teamId,
      type: `коллектив`,
      title: team[teamId].title,
      city: team[teamId].city || ``,
      typeSearch
    };
  });
};

const parseOrganizations = (organization, typeSearch = `other`) => {
  if (isEmpty(organization)) {
    return [];
  }

  return Object.keys(organization)
  .map((organizationId) => {
    return {
      organizationId,
      type: `организация`,
      title: organization[organizationId].title,
      city: organization[organizationId].city || ``,
      typeSearch
    };
  });
};

const parseCity = (city, typeSearch = `other`) => {
  if (isEmpty(city)) {
    return [];
  }

  return Object.keys(city)
  .map((cityId) => {
    return {
      cityId,
      type: `город`,
      city: city[cityId].name || ``,
      googleId: city[cityId].google_city || ``,
      typeSearch,
    };
  });
};

const parsePeople = (peopleData, typeSearch = `other`) => {
  if (isEmpty(peopleData)) {
    return [];
  }

  return Object.keys(peopleData)
  .map((peopleId) => {
    return {
      peopleId,
      type: `человек`,
      surname: peopleData[peopleId].surname || ``,
      lastname: peopleData[peopleId].lastname || ``,
      middlename: peopleData[peopleId].middlename || ``,
      emailMask: peopleData[peopleId].email || ``,
      phoneMask: peopleData[peopleId].phone || ``,
      city: peopleData[peopleId].city || ``,
      typeSearch
    };
  });
};

export default class SearchParsing {
  static performance(data) {
    const performance = data.performance;

    if (!performance || performance.count === 0) {
      return [];
    }

    return Object.keys(performance)
    .filter((key) => key !== `count`)
    .map((performanceId) => {
      return {
        performanceId,
        title: performance[performanceId]
      };
    });
  }

  static team(data) {
    const team = data.team;

    if (!team || team.count === 0) {
      return [];
    }

    return [].concat(parseTeams(team.related, `related`), parseTeams(team.other, `other`));
  }

  static organization(data) {
    const organization = data.organization;

    if (!organization || organization.count === 0) {
      return [];
    }

    return [].concat(parseOrganizations(organization.related, `related`), parseOrganizations(organization.other, `other`));
  }

  static city(data) {
    const city = data.city;

    if (!city || city.count === 0) {
      return [];
    }

    return [].concat(parseCity(city.related, `related`), parseCity(city.other, `other`));
  }

  static people(data) {
    const people = data.people;

    if (!people || people.count === 0) {
      return [];
    }

    return [].concat(parsePeople(people.related, `related`), parsePeople(people.other, `other`));
  }
}
