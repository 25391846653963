import {DEFAULT_FILTER} from "../../const/filter";
import {NTR} from "../../name-to-request";
import RequestController from "../../static/request/request";
import {isEmptyArray} from "../../utils/array/is-empty-array/is-empty-array";
import {isEmptyObject} from "../../utils/common";
import {getFormattedRating} from "../../utils/rating";
import PerformanceModel from "./model";
import PerformanceParsing from "./parsing";
import PerformanceRequest from "./request";

export default class PerformanceData {
  constructor({api, isShowError = true}) {
    this._api = api;
    this._isShowError = isShowError;

    this._performance = null;
  }

  // constructor({api, isShowError = true}) {
  //   if (typeof PerformanceData.instance === `object`) {
  //     return PerformanceData.instance;
  //   }
  //   PerformanceData.instance = this;
  //   this._api = api;
  //   this._isShowError = isShowError;

  //   this._performance = null;

  //   return PerformanceData.instance;
  // }

  getOneData({performanceId, parameters, filter = DEFAULT_FILTER}) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.getOne,
        parameter: {
          performanceId,
          parameters,
          filter,
        },
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [`data`],
        parseResponse: PerformanceModel.parsePerformance,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по выступлению.`),
      }).send();
    });
  }

  getData2({count = `all`, order, parameters, filter = DEFAULT_FILTER}) {
    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.getSeveral,
        parameter: {
          count,
          order,
          parameters,
          filter,
        },
        checkRequiredParameter: {},
        requiredResponseParameters: [`data`],
        parseResponse: PerformanceModel.parsePerformances,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по выступлению.`),
      }).send();
    });
  }

  getAllData({parameters, order, filter = DEFAULT_FILTER}) {
    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.getAll,
        parameter: {
          parameters,
          order,
          filter,
        },
        checkRequiredParameter: {},
        requiredResponseParameters: [`data`],
        parseResponse: PerformanceModel.parsePerformances,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по выступлениям.`),
      }).send();
    });
  }

  delete(performanceId) {
    const getTextError = ({message}) => {
      if (message === `Performance rating exists`) {
        return `Данное выступление не может быть оценено, т.к. для него в системе содержатся оценки судей. 
        <br>Вы можете: <br>- Переместить выступление в архив <br>- Обратиться в техническую поддержку, для получения подробностей`;
      }

      return `Не удалось удалить выступление`;
    };

    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.delete,
        parameter: {performanceId},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }

  setRefereeRating(performanceId, refereeId, ratings) {
    const rating = getFormattedRating(ratings);

    if (isEmptyObject(rating)) {
      return Promise.resolve(null);
    }

    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setRefereeRating,
        parameter: {performanceId, refereeId, rating},
        checkRequiredParameter: {performanceId, refereeId, rating},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => resolve(error),
      }).send();
    });
  }

  setRefereeRatings(performanceId, refereeRatings, onResolveHandler = () => {}) {
    return Promise.all(
        refereeRatings.map((referee) => {
          return this.setRefereeRating(performanceId, referee.refereeId, referee.ratings);
        })
    )
    .then((responses) => {
      const errorResponses = responses.filter((response) => !!response);
      if (isEmptyArray(errorResponses)) {
        onResolveHandler();
        return;
      }

      errorResponses[0].showCustom(`Не удалось сохранить оценки.`);
    });
  }

  // {"set_performance_rating":{"performance_id":"52418",
  // "rating":{
  //   "71":{"criteria":{"139872":"1","139873":"1"}},
  //   "88":{"criteria":{"139872":"2","139873":"4"}}
  // }}}

  setRefereeRatingAll(performanceId, ratingReferees, criterias = []) {
    if (isEmptyObject(ratingReferees)) {
      return Promise.resolve(null);
    }

    const rating = {};

    ratingReferees.forEach((referee) => {
      const criteria = {};

      referee.ratings.forEach((it) => {
        if (it.rating) {
          criteria[it.criteriaId] = it.rating;
        }
      });
      if (!isEmptyObject(criteria)) {
        rating[referee.refereeId] = {};
        rating[referee.refereeId].criteria = criteria;
      }
    });

    const getCriteriaTitle = (criteriaId) => {
      const criteria = criterias.find((it) => it.criteriaIds.includes(criteriaId));
      return criteria ? criteria.title : criteriaId;
    };

    const getTextError = ({message, parameter}) => {
      if (message === `rating exceeds max`) {
        const errorInfo = parameter.error_info;
        const match = errorInfo.match(/^(\d+):(\d+)>(\d+)$/);
        const criteriaId = match[1];
        const criteriaTitle = getCriteriaTitle(criteriaId);

        if (match) {
          return `Для критерия <b>${criteriaTitle}</b> превышена максимально допустимая оценка.
            Передано <b>${match[2]}</b>,
            максимально допустимо: <b>${match[3]}</b>.<br><br>
   
          Оценка не записана! Измените оценку или максимальный предел.`;
        }
      }

      return `Не удалось сохранить оценки`;
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setRefereeRatingAll,
        parameter: {performanceId, rating},
        checkRequiredParameter: {performanceId, rating},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }

  setCriteriaRating(performanceId, rating, criterias = []) {
    const getCriteriaTitle = (criteriaId) => {
      const criteria = criterias.find((it) => it.criteriaIds.includes(criteriaId));
      return criteria ? criteria.title : criteriaId;
    };

    const getTextError = ({message, parameter}) => { // частично повтор
      if (message === `rating exceeds max`) {
        const errorInfo = parameter.error_info;
        const match = errorInfo.match(/^(\d+):(\d+)>(\d+)$/);
        const criteriaId = match[1];
        const criteriaTitle = getCriteriaTitle(criteriaId);

        if (match) {
          return `Для критерия <b>${criteriaTitle}</b> превышена максимально допустимая оценка.
            Передано <b>${match[2]}</b>,
            максимально допустимо: <b>${match[3]}</b>.<br><br>
   
          Оценка не записана! Измените оценку или максимальный предел.`;
        }
      }

      return `Не удалось проголосовать за данное выступление`;
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setCriteriaRating,
        parameter: {performanceId, rating},
        checkRequiredParameter: {performanceId, rating},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
        // onRequestErrorHandler: (error) => error.showCustom(`Не удалось проголосовать за данное выступление.`),
      }).send();
    });
  }

  setArchive(performanceId, isArchive) {
    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setArchive,
        parameter: {performanceId, isArchive},
        checkRequiredParameter: {performanceId, isArchive},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось перенести в архив или извлечь из архива.`),
      }).send();
    });
  }

  setStatusMoney(performanceId, status) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setStatusMoney,
        parameter: {performanceId, status},
        checkRequiredParameter: {performanceId, status},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось сохранить данные об оплате.`),
      }).send();
    });
  }

  setToPayMoney(performanceId, toPay, status) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setToPayMoney,
        parameter: {performanceId, toPay, status},
        checkRequiredParameter: {performanceId, toPay, status},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось сохранить данные об оплате.`),
      }).send();
    });
  }

  setPaidMoney(performanceId, paid, status) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setPaidMoney,
        parameter: {performanceId, paid, status},
        checkRequiredParameter: {performanceId, paid, status},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось сохранить данные об оплате.`),
      }).send();
    });
  }

  // sendEmail(performanceId, mailId, template, userMessage) {
  //   return new Promise((resolve, reject) => {
  //     new RequestController({
  //       api: this._api,
  //       getRequest: PerformanceRequest.sendEmail,
  //       parameter: {performanceId, mailId, template, userMessage},
  //       checkRequiredParameter: {performanceId, mailId, template},
  //       requiredResponseParameters: [],
  //       isShowError: this._isShowError,
  //       onResolveHandler: () => resolve(),
  //       onRejectHandler: () => reject(),
  //       onRequestErrorHandler: (error) => error.showCustom(`Не удалось отправить письмо.`),
  //       // onRequestErrorHandler: (error) => resolve(error),
  //     }).send();
  //   });
  // }

  setContest(performanceId, contestId) {
    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.contest,
        parameter: {performanceId, contestId},
        checkRequiredParameter: {performanceId, contestId},
        requiredResponseParameters: [],
        // parseResponse: PerformanceParsing.performanceId,
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось прикрепить выступление к мероприятию.`),
      }).send();
    });
  }

  setTitle(performanceId, title) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.title,
        parameter: {performanceId, title},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [NTR.PERFORMANCE_ID],
        parseResponse: PerformanceParsing.performanceId,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось сохранить название выступления.`),
      }).send();
    });
  }

  setCity(performanceId, city, googleId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.city,
        parameter: {performanceId, city, googleId},
        checkRequiredParameter: {performanceId, city, googleId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось сохранить город выступления.`),
      }).send();
    });
  }

  createGroup(performanceId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.createGroup,
        parameter: {performanceId},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [NTR.GROUP_ID],
        parseResponse: PerformanceParsing.groupIdData,
        isShowError: this._isShowError,
        onResolveHandler: (groupId) => resolve(groupId),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось создать группу.`),
      }).send();
    });
  }

  changeGroup(performanceId, groupId) {
    const getTextError = ({message}) => {
      if (message === `maximum count of performances per group`) {
        return `Достигнуто максимальное число выступлений в группе`;
      }

      if (message === `performances from different nominations`) {
        return `Номинация выступления не соответствует номинации группы`;
      }

      if (/^performance group .*? not exists$/.test(message)) {
        return `Такая группа не существует`;
      }

      return `Не удалось изменить группу`;
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.changeGroup,
        parameter: {performanceId, groupId},
        checkRequiredParameter: {performanceId, groupId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(groupId),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }

  deleteGroup(performanceId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.changeGroup,
        parameter: {performanceId, groupId: NTR.ZERO},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить группу.`),
      }).send();
    });
  }

  setNomination(performanceId, nominationId) {
    const getTextError = ({message}) => {
      if (message === `new nomination = old nomination`) {
        return `Эта номинация уже установлена для текущего выступления`;
      }

      if (message === `performance_id and nomination_id from different contests`) {
        return `Не удалось установить номинацию, т.к. номинация относится к другому мероприятию.`;
      }

      if (message === `performance rated in old nomination`) {
        return `Не удалось установить номинацию, т.к выступление имеет оценки в исходной номинации.`;
      }

      return `Не удалось установить номинацию`;
    };


    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.nomination,
        parameter: {performanceId, nominationId},
        checkRequiredParameter: {performanceId, nominationId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => {
          const text = getTextError(error);
          error.showCustom(text);
        },
      }).send();
    });
  }

  setAgeCategory(performanceId, ageCategoryId) {
    const getTextError = ({message}) => {
      if (message === `age_category and contest doesn't match`) {
        return `Не удалось установить возрастную категорию, т.к. она относится к другому мероприятию.`;
      }

      return `Не удалось установить возрастную категорию`;
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.ageCategory,
        parameter: {performanceId, ageCategoryId},
        checkRequiredParameter: {performanceId, ageCategoryId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }

  deleteAgeCategory(performanceId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.ageCategory,
        parameter: {performanceId, ageCategoryId: NTR.ZERO},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить возрастную категорию.`),
      }).send();
    });
  }

  setQualification(performanceId, qualificationId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.qualification,
        parameter: {performanceId, qualificationId},
        checkRequiredParameter: {performanceId, qualificationId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить квалификацию.`),
      }).send();
    });
  }

  setTeam(performanceId, teamId, onForceHandler = () => {}) {
    const getTextError = ({message}) => {
      if (message === `Value pair organization_id and team_id not found`) {
        return `В сервисе нет информации, что коллектив работает в организации.`;
      }

      if (/team_organization \(.*?\) != performance_organization \(.*?\)/.test(message)) {
        onForceHandler();
        return null;
      }

      return `Не удалось установить коллектив.`;
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.team,
        parameter: {performanceId, teamId},
        checkRequiredParameter: {performanceId, teamId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }

  setTeamForce(performanceId, teamId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.teamForce,
        parameter: {performanceId, teamId},
        checkRequiredParameter: {performanceId, teamId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить коллектив.`),
      }).send();
    });
  }

  deleteTeam(performanceId, teamId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.deleteTeam,
        parameter: {performanceId, teamId},
        checkRequiredParameter: {performanceId, teamId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить коллектив.`),
      }).send();
    });
  }

  setOrganization(performanceId, organizationId, onForceHandler) {
    const getTextError = ({message}) => {
      if (message === `Value pair organization_id and team_id not found`) {
        return `В сервисе нет информации, что коллектив работает в организации.`;
      }

      if (/team_organization \(.*?\) != performance_organization \(.*?\)/.test(message)) {
        onForceHandler();
        return null;
      }

      return `Не удалось установить организацию.`;
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.organization,
        parameter: {performanceId, organizationId},
        checkRequiredParameter: {performanceId, organizationId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }

  setOrganizationForce(performanceId, organizationId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.organizationForce,
        parameter: {performanceId, organizationId},
        checkRequiredParameter: {performanceId, organizationId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить организацию.`),
      }).send();
    });
  }

  deleteOrganization(performanceId, organizationId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.deleteOrganization,
        parameter: {performanceId, organizationId},
        checkRequiredParameter: {performanceId, organizationId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить организацию.`),
      }).send();
    });
  }

  setDirector(performanceId, peopleId, positions = []) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setDirector,
        parameter: {performanceId, peopleId, positionText: positions.join(`,`)},
        checkRequiredParameter: {performanceId, peopleId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить руководителя.`),
      }).send();
    });
  }

  setNoDirectors(performanceId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setDirector,
        parameter: {performanceId, peopleId: NTR.ZERO, positionText: NTR.EMPTY},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить «У меня нет руководителя».`),
      }).send();
    });
  }

  deleteDirector(directorId) {
    const getTextError = ({message}) => {
      if (/director .*? not found/.test(message)) {
        return `Руководитель не найден`;
      }

      return `Не удалось удалить руководителя.`;
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.deleteDirector,
        parameter: {directorId},
        checkRequiredParameter: {directorId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }

  setPlayer(performanceId, peopleId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setPlayer,
        parameter: {performanceId, peopleId},
        checkRequiredParameter: {performanceId, peopleId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить участника.`),
      }).send();
    });
  }

  deletePlayer(performanceId, playerId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.deletePlayer,
        parameter: {performanceId, playerId},
        checkRequiredParameter: {performanceId, playerId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить участника.`),
      }).send();
    });
  }

  setPlayerCount(performanceId, count) {
    // warning: "price interval not found"
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.playerCount,
        parameter: {performanceId, count},
        checkRequiredParameter: {performanceId, count},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить число участников.`),
      }).send();
    });
  }

  isPlayerCountName(performanceId, isCheck) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.isPlayerCountName,
        parameter: {performanceId, isCheck},
        checkRequiredParameter: {performanceId, isCheck},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить показ названия формы ансамбля.`),
      }).send();
    });
  }

  setContactPeople(performanceId, peopleId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setContactPeople,
        parameter: {performanceId, peopleId},
        checkRequiredParameter: {performanceId, peopleId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить контактное лицо.`),
      }).send();
    });
  }

  setPhonogram(performanceId, phonogramId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setPhonogram,
        parameter: {performanceId, phonogramId},
        checkRequiredParameter: {performanceId, phonogramId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить фонограмму.`),
      }).send();
    });
  }

  setPerformanceDuration(performanceId, duration) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setPerformanceDuration,
        parameter: {performanceId, duration},
        checkRequiredParameter: {performanceId, duration},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить длительность выступления.`),
      }).send();
    });
  }

  setIsRequiredPhonogram(performanceId, isCheck) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setIsRequiredPhonogram,
        parameter: {performanceId, isCheck},
        checkRequiredParameter: {performanceId, isCheck},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось изменить «Фонограмма не требуется».`),
      }).send();
    });
  }

  setUserField(fieldId, performanceId, value) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setUserField,
        parameter: {fieldId, performanceId, value},
        checkRequiredParameter: {fieldId, performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось сохранить значение пользовательского поля.`),
      }).send();
    });
  }

  addLink(performanceId, link) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.addLink,
        parameter: {performanceId, link},
        checkRequiredParameter: {performanceId, link},
        requiredResponseParameters: [NTR.LINK_ID],
        parseResponse: PerformanceParsing.linkId,
        isShowError: this._isShowError,
        onResolveHandler: (linkId) => resolve(linkId),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось добавить ссылку.`),
      }).send();
    });
  }

  getLink(link) {
    return new Promise((resolve, reject) => {
      const requestController = new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.getLink,
        parameter: {link},
        checkRequiredParameter: {link},
        requiredResponseParameters: [NTR.LINK],
        parseResponse: PerformanceParsing.link,
        isShowError: this._isShowError,
        onResolveHandler: (linkData) => resolve(linkData),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку.`),
      });

      requestController.send();
    });
  }

  deleteLinks(performanceId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.deleteLinks,
        parameter: {performanceId},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить ссылки.`),
      }).send();
    });
  }

  deleteFiles(performanceId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.deleteFiles,
        parameter: {performanceId},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить файлы.`),
      }).send();
    });
  }

  changeLink(linkId, link) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.changeLink,
        parameter: {linkId, link},
        checkRequiredParameter: {linkId, link},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось изменить ссылку.`),
      }).send();
    });
  }

  deleteLink(linkId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.deleteLink,
        parameter: {linkId},
        checkRequiredParameter: {linkId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить ссылку.`),
      }).send();
    });
  }

  deleteFile(fileId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.deleteFile,
        parameter: {fileId},
        checkRequiredParameter: {fileId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить файл.`),
      }).send();
    });
  }

  addFile(performanceId, fileId) {
    // доавить парсинг
    //   file_id: 234
    // result: "OK"
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.addFile,
        parameter: {performanceId, fileId},
        checkRequiredParameter: {performanceId, fileId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось добавить файл.`),
      }).send();
    });
  }

  copy(performances) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.copy,
        parameter: {performances},
        checkRequiredParameter: {performances},
        requiredResponseParameters: [NTR.PERFORMANCE_ID],
        parseResponse: PerformanceParsing.performanceIds,
        isShowError: this._isShowError,
        onResolveHandler: (performanceIds) => resolve(performanceIds),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось скопировать выступления.`),
      }).send();
    });
  }

  setNote(performanceId, note, authorType = `people`) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setNote,
        parameter: {performanceId, authorType, note},
        checkRequiredParameter: {performanceId, authorType, note},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить комментарий.`),
      }).send();
    });
  }

  setRegistration(performanceId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setRegistration,
        parameter: {performanceId},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить регистратора`),
        // onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить комментарий.`),
      }).send();
    });
  }

  setTurn(ids, target) {
    // error: "type error: turn_id=lm_201"
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setTurn,
        parameter: {ids: ids.join(`,`), target},
        checkRequiredParameter: {ids: ids.join(`,`), target},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось сохранить порядок выступлений.`),
      }).send();
    });
  }

  setJoin(performanceId, join) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PerformanceRequest.setJoin,
        parameter: {performanceId, join},
        checkRequiredParameter: {performanceId, join},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось заблокировать/разблокировать.`),
      }).send();
    });
  }
}
