import {USER_FIELD_CODE_PREFIX} from "../../const/user-field-code-prefix";
import {UserFieldType} from "../../const/user-field-type";

const getUserFieldDropDowns = (registrationData, prefix) => {
  return Object.keys(registrationData)
  .filter((key) => key.indexOf(prefix) === 0)
  .map((key) => {
    return {
      userFieldId: key.slice(prefix.length),
      value: registrationData[key],
    };
  })
  .filter((field) => !!field.value && field.value !== `0` && field.value !== ``);
};


const getUserFieldPeoples = (registrationData, prefix) => {
  let result = [];

  Object.keys(registrationData)
  .filter((key) => key.indexOf(prefix) === 0)
  .map((key) => {
    const userFieldId = key.slice(prefix.length);
    const peoples = registrationData[key] || [];

    const peopleData = peoples.map((people) => {
      return {
        userFieldId,
        value: String(people.peopleId) || ``,
      };
    });

    result = [].concat(result, peopleData);
  });

  return result;
};

const getUserFieldData = (registrationData, prefix) => {
  return Object.keys(registrationData)
  .filter((key) => key.indexOf(prefix) === 0)
  .map((key) => {
    return {
      userFieldId: key.slice(prefix.length),
      value: registrationData[key],
    };
  })
  .filter((field) => !!field.value);
};

export const prepareUserFieldsData = (registrationData) => {
  return Object.keys(UserFieldType).reduce((result, type) => {
    const userFieldType = UserFieldType[type];
    const prefix = USER_FIELD_CODE_PREFIX[userFieldType];
    let data = [];

    if (userFieldType === UserFieldType.DROPDOWN) {
      data = getUserFieldDropDowns(registrationData, prefix);
    } else if (userFieldType === UserFieldType.PEOPLE) {
      data = getUserFieldPeoples(registrationData, prefix);
    } else {
      data = getUserFieldData(registrationData, prefix);
    }

    return result.concat(data);
  }, []);
};
