import {SEARCH_CHAR_MIN} from "../const";
import SearchData from "../data/get/search";
import SearchNewModel from "../model/search-new";
import RequestController from "../static/request/request";
import {debounce} from "../utils/debounce";

const EMPTY_SEARCHED_DATA = {
  performances: [],
  teams: [],
  organizations: [],
};


export default class SearchController {
  constructor() {
    this._onInputHandler = this._onInputHandler.bind(this);
  }

  init() {
    this._inputComponent.setInputHandler(debounce(this._onInputHandler));
  }

  search(query) {
    return this._getSearchData(query);
  }

  _onInputHandler(value) {
    this._search(value);
  }

  async _search(query) {
    const data = await this._getSearchData(query);
    this._showSearched(data, query);
  }

  _getSearchData(query) {
    if (!query) {
      return Promise.resolve(EMPTY_SEARCHED_DATA);
    }

    if (query.length < SEARCH_CHAR_MIN) {
      return Promise.resolve(EMPTY_SEARCHED_DATA);
    }

    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: SearchData.get,
        parameter: {area: this._area, text: query, filter: this._getFilter()},
        checkRequiredParameter: {area: this._area},
        requiredResponseParameters: [this._area],
        parseResponse: SearchNewModel.parseSearchData,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось произвести поиск.`),
      }).send();
    });
  }
}
