/**
 *
 */

import {ModeRenderWrap} from "../../../const";
import {DEFAULT_CONTACT} from "../../../const/default-contact";
import PeopleData from "../../../data/people/people";
import {isEmptyArray} from "../../../utils/array/is-empty-array/is-empty-array";
import {getTextName} from "../../../utils/name";
import ContactPeopleController from "../../people/contact-people";

export default class ContactPeoplesController {
  constructor({api, registrator = null, contacts = [], players = [], directors = [], onDataChangeHandler, onChangeContactPeoples, onEmailChangeHandler}) {
    this._api = api;
    this._registrator = registrator;
    this._players = players;
    this._directors = directors;

    this._contacts = contacts;

    this._onDataChangeHandler = onDataChangeHandler;
    this._onChangeContactPeoples = onChangeContactPeoples;
    this._onEmailChangeHandler = onEmailChangeHandler;

    this._peoples = [];
  }

  get contactPeoples() {
    return this._peoples;
  }

  init() {
    this._getPeoples();
  }

  addContact({handler}) {
    new ContactPeopleController({
      contactPeople: Object.assign({}, DEFAULT_CONTACT),
      peopleData: new PeopleData({api: this._api}),
      isMultiWrapInput: false,
      modeRenderEmail: ModeRenderWrap.ONE,
      modeRenderPhone: ModeRenderWrap.ONE,
      onAddContactPeople: (contact) => {
        this._contacts.push(contact);
        handler(contact);
        this._getPeoples();

        this._onDataChange();
        this._onDataChangeHandler(this._contacts);
      },
    }).addContactPeople();
  }

  _onDataChange() {
    this._onChangeContactPeoples({
      peoples: this._peoples,
      players: this._players,
      directors: this._directors,
    });
  }

  changePlayers(players) {
    this._players = players;
    this._getPeoples();

    this._onDataChange();
  }

  changeDirectors(directors) {
    this._directors = directors;
    this._getPeoples();

    this._onDataChange();
  }

  changeContactEmail(contact, email) {
    if (this._registrator && this._registrator === contact) {
      return;
    }

    const indexPlayer = this._players.findIndex((player) => player === contact);

    if (indexPlayer >= 0) {
      this._onEmailChangeHandler(`player`, this._players[indexPlayer].peopleId, email);
      return;
    }

    const indexDirector = this._directors.findIndex((director) => director === contact);

    if (indexDirector >= 0) {
      this._onEmailChangeHandler(`director`, this._directors[indexDirector].peopleId, email);
    }
  }

  getPeoples() {
    return [].concat(this._contacts, this._registrator, this._players, this._directors);
  }

  getPeople(peopleId) {
    const peoples = this.getPeoples();
    return peoples.find((people) => people.peopleId === peopleId);
  }

  _getContact(people) {
    return {
      id: people.peopleId,
      text: `${getTextName(people)} (${this._getEmailContactPeople(people)})`,
    };
  }

  _getRegistrator(people) {
    return {
      id: people.peopleId,
      text: `${getTextName(people)}, регистратор (${this._getEmailContactPeople(people)})`,
    };
  }

  _getPlayer(people) {
    return {
      id: people.peopleId,
      text: `${getTextName(people)}, участник (${this._getEmailContactPeople(people)})`,
    };
  }

  _getDirector(people) {
    return {
      id: people.peopleId,
      text: `${getTextName(people)}, руководитель (${this._getEmailContactPeople(people)})`,
    };
  }

  _getPeoples2() {
    this._peoples = [].concat(this._contacts, this._registrator, this._players, this._directors);
  }

  _getPeoples() {
    const peoples = [];

    this._contacts.forEach((contact) => {
      peoples.push(this._getContact(contact));
    });

    if (this._registrator) {
      peoples.push(this._getRegistrator(this._registrator));
    }

    this._players.forEach((player) => {
      peoples.push(this._getPlayer(player));
    });

    this._directors.forEach((director) => {
      peoples.push(this._getDirector(director));
    });

    this._peoples = peoples;
  }

  isNoEmail(people) {
    return !people || !people.emails || isEmptyArray(people.emails) || !people.emails[0] || !people.emails[0].mail;
  }

  _getEmailContactPeople(people) {
    if (this.isNoEmail(people)) {
      return `email не указан`;
    }

    return people.emails[0].mail;
  }
}
