import {Language, LanguageToEngText} from "../../const";

export const getTabRegistration = (languageData, nameTab, defaultValue = ``) => {
  if (!languageData) {
    return defaultValue;
  }

  if (!nameTab) {
    return defaultValue;
  }

  const language = languageData.language;

  if (language === LanguageToEngText[Language.RU]) {
    return languageData.getData(`tab_${nameTab}`) || defaultValue;
  }

  return languageData.getData(`tab_${nameTab}`) || `${defaultValue} (${language})`;


  // if (typeField === ComponentType.FIELD) {
  //   if (language === LanguageToEngText[Language.RU]) {
  //     return languageData[nameField] || defaultValue || `${DefaultTitleRegistrationField[nameField]}`;
  //   }

  //   return languageData[nameField] || `${DefaultTitleRegistrationField[nameField]} (${language})`;
  // }

  // if (typeField === ComponentType.USER_FIELD) {
  //   return languageData.getUserFieldTitle(nameField) || `${defaultValue} (${language})`;
  // }

  // if (typeField === ComponentType.TEXT) {
  //   return languageData.getData(`${ComponentType.TEXT}_${nameField}`) || `${defaultValue} (${language})`;
  // }

  // return defaultValue;
};
