import AliasItemComponent from "../../component/alias/alias";
import DivComponent from "../../component/base/div";
import ParagraphComponent from "../../component/base/paragraph";
import {AliasToText, ButtonTitle, Language, LanguageToRusText} from "../../const";
import Popup, {ContentType, PopupSize} from "../../static/popup/popup";
import {copyToClipboard} from "../../utils/common";
import {render, RenderPosition} from "../../utils/render";
import ButtonController from "../button";
import CopyLinkButtonController from "../buttons/copy-link";
import EditButtonController from "../buttons/edit";
import TrashButtonController from "../buttons/trash";

const LanguagesForAlias = [
  Language.RU,
  Language.EN,
  Language.KK,
  Language.DE,
];


export default class AliasController {
  constructor() {
    this._language = ``;
    this._surname = ``;
    this._lastname = ``;
    this._middlename = ``;
    this._title = ``;

    this._popup = null;

    this._isEdit = true;

    this._surnameInput = null;
    this._lastnameInput = null;
    this._middlenameInput = null;

    this._teamInput = null;
    this._organizationInput = null;

    this._editPopupController = null;
    this._addPopupController = null;

    this._caseController = ``;


    this._addButtonTitle = `+ Добавить название на другом языке`;

    this._onAddAliasButtonClickHandler = this._onAddAliasButtonClickHandler.bind(this);
    this._onDeleteButtonClickHandler = this._onDeleteButtonClickHandler.bind(this);
    this._onEditButtonClickHandler = this._onEditButtonClickHandler.bind(this);
    this._onAddButtonPopupClickHandler = this._onAddButtonPopupClickHandler.bind(this);
    // this._onLanguageCheckHandler = this._onLanguageCheckHandler.bind(this);
  }

  render() {
    this._renderAliasesList();
    this._renderAddButton();
  }

  renderAddPopup() {
    this._renderAddPopup();
  }

  _renderAliasesList() {
    this._aliases
    .sort((a, b) => {
      if (a.language.toLowerCase() < b.language.toLowerCase()) {
        return -1;
      }
      if (a.language.toLowerCase() > b.language.toLowerCase()) {
        return 1;
      }
      return 0;
    })
    .forEach((alias) => {
      const aliasComponent = new AliasItemComponent({
        alias,
      });

      if (this._isEdit) {
        this._renderDeleteButton(aliasComponent.getElement().querySelector(`.master__tile`), alias);
        this._renderEditButton(aliasComponent.getElement().querySelector(`.master__tile`), alias);
      }

      render(this._container, aliasComponent.getElement(), RenderPosition.BEFOREEND);
    });
  }

  _renderAddButton() {
    new ButtonController({
      container: this._container,
      className: `modal__button modal__button--asLink`,
      title: this._addButtonTitle,
      style: `background-color: transparent;`,
      onClickHandler: this._onAddAliasButtonClickHandler,
      isDisabled: true,
    }).render();
  }

  _renderDeleteButton(container, alias) {
    new TrashButtonController({
      container,
      onClickHandler: () => this._onDeleteButtonClickHandler(alias),
    }).render();
  }

  _renderEditButton(container, alias) {
    new EditButtonController({
      container,
      onClickHandler: () => this._onEditButtonClickHandler(alias),
    }).render();
  }

  _renderEditPopup({language, title}) {
    Popup.showCustom({
      size: PopupSize.SMALL,
      title: `Изменить написание`,
      onClosePopupHandler: () => {
        this._editPopupController = null;
      },
      contents: [
        {
          type: ContentType.TEXT,
          content: `${AliasToText[language]}:`,
        },
        {
          type: ContentType.INPUT_NEW,
          content: {
            type: `text`,
            label: `Название`,
            name: `title`,
            value: title,
            isRequired: true,
          }
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: ButtonTitle.CANCEL,
              isClose: true,
            },
            {
              title: ButtonTitle.CHANGE,
              onClickHandler: () => this._onChangeButtonClickHandler(language),
              isRequired: true,
              isClose: true,
            }
          ],
        }
      ]
    })
    .then((popupController) => {
      this._editPopupController = popupController;
    });
  }

  _getLanguageContent() {
    return {
      type: ContentType.INPUT_NEW,
      content: {
        type: `text`,
        label: `Язык`,
        name: `language`,
        value: ``,
        inputId: ``,
        isReadOnly: true,
        // isRequired: true,
        dropDownItems: this._getLanguageItems(),
        onItemCheckHandler: (language) => {
          this._showCase(language);
          // this._checkAliases(language);
        },
      }
    };
  }

  _getLanguageItems() {
    // const langs = Object.keys(Language);

    // return langs
    //   .map((lang) => {
    //     return {
    //       id: Language[lang],
    //       text: LanguageToText[Language[lang]],
    //     };
    //   });

    return LanguagesForAlias
      .map((lang) => {
        return {
          id: lang,
          text: LanguageToRusText[lang],
        };
      });
  }

  _getTitleComponent(title) {
    const wrap = new DivComponent(``);
    wrap.getElement().style = `
      display: flex;
      align-items: center;
      justify-content: space-between;
    `;

    const text = new ParagraphComponent({
      text: `<b>RU</b>: ${title}`
    });

    render(wrap.getElement(), text.getElement(), RenderPosition.BEFOREEND);

    new CopyLinkButtonController({
      container: wrap.getElement(),
      prompt: `Сохранить название в буфер`,
      onClickHandler: () => {
        copyToClipboard(title);
      },
    }).render();

    return wrap.getElement();
  }

  _showCase(language) {
    this._caseController.show(language);
  }


  _fillAlias(alias) {
    this._addPopupController.inputs
      .filter((input) => input.name === `title`)
      .forEach((input) => {
        input.value = alias[input.name];
      });
  }

  _setDataAlias() {}

  _onAddAliasButtonClickHandler() {
    this._renderAddPopup();
  }

  _onDeleteButtonClickHandler({language}) {
    // вы точно хотите удалить алиас
    this._setDataAlias({language, title: ``});
  }

  _onEditButtonClickHandler(alias) {
    this._renderEditPopup(alias);
  }

  _onChangeButtonClickHandler(language) {
    const title = this._editPopupController.value.title;

    const alias = {
      language,
      title,
    };

    this._setDataAlias(alias);
  }

  _onResolveRequest() {
    Popup.showResolveChangeNotification();
    this._onAliasChange();
  }
}
