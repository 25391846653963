const getNameStatisticPerName = (statistic) => {
  return Object.keys(statistic).map((type) => {
    return {
      type,
      rating: +statistic[type],
    };
  });
};

export default class StatisticNameParsing {
  static peopleNameType(data) {
    const statisticData = data.people_name_type;

    return Object.keys(statisticData).map((title) => {
      const typeStatistics = getNameStatisticPerName(statisticData[title]);

      return {
        title,
        typeStatistics,
      };
    });
  }
}
