import ParagraphComponent from "../../../component/base/paragraph";
import {ComponentType} from "../../../const/component-type";
import {getTitleRegistrationField} from "../../../utils/get-title-registration-field/get-title-registration-field";
import {RenderPosition, render} from "../../../utils/render";

export default class TextController {
  constructor({container, isShow = true, setting, languageController}) {
    this._container = container;
    this._isShow = isShow;
    this._setting = setting;
    this._languageController = languageController;

    this._textComponent = null;
  }

  get isShow() {
    return this._isShow;
  }

  set isShow(data) {
    this._isShow = data;
  }

  show() {
    this.isShow = true;

    if (this._textComponent) {
      this._textComponent.show();
    }
  }

  hide() {
    this.isShow = false;
    if (this._textComponent) {
      this._textComponent.hide();
    }
  }

  render(container) {
    if (container) {
      this._container = container;
    }
    // _getTitleValue() {
    //   return getTitleRegistrationField(this._languageController, `performance`, this._setting.title);
    // }
    // debugger;
    this._textComponent = new ParagraphComponent({
      className: `text text--${this._setting.position} text--${this._setting.style}`,
      text: getTitleRegistrationField(this._languageController, this._setting.componentId, this._setting.pattern, ComponentType.TEXT),
      // text: this._setting.pattern,
    });

    this._textComponent.getElement().style = `margin-top: 10px;`;

    render(this._container, this._textComponent.getElement(), RenderPosition.BEFOREEND);

    if (!this.isShow) {
      this._textComponent.hide();
    }
  }
}
