import DivComponent from "../../component/base/div";
import {ModeRenderWrap} from "../../const";
import {render, RenderPosition} from "../../utils/render";
import ButtonController from "../button";


export default class MultiInputsController {
  constructor() {
    this._wrapComponent = null;
    this._inputComponents = [];
    this._isShowAddButton = false;
    this._modeRender = ModeRenderWrap.ALL;
    this._label = ``;
    this._errorValidateText = ``;

    this._onAddButtonClickHandler = this._onAddButtonClickHandler.bind(this);
    this._onInputHandler = this._onInputHandler.bind(this);
    this._onDeleteHandler = this._onDeleteHandler.bind(this);
  }

  get label() {
    return this._label;
  }

  get errorValidateText() {
    return this._errorValidateText;
  }

  get isValid() {
    return this._inputComponents[0].isValid;
  }

  showValidate() {
    this._inputComponents[0].showValidate();
  }

  setInputHandler(handler) {
    this._handler = handler;
  }

  addInput(component) {
    this._inputComponents.push(component);

    component.setInputHandler(this._onInputHandler);
    component.setDeleteButtonClickHandler(this._onDeleteHandler);
  }

  render(container) {
    this._wrapComponent = new DivComponent(``);
    this._wrapComponent.getElement().style = `display: grid;`;
    render(container, this._wrapComponent.getElement(), RenderPosition.BEFOREEND);

    this._renderAddButton();
    this._renderInputs();
  }

  _renderInputs() {}

  _renderAddButton() {
    if (!this._isShowAddButton) {
      return;
    }

    new ButtonController({
      container: this._wrapComponent.getElement(),
      className: `popup__buttonAdd`,
      style: `grid-row-end: 100;`,
      title: this._addButtonTitle,
      onClickHandler: this._onAddButtonClickHandler,
    }).render();
  }

  _renderEmptyInput() {
    // debugger;
  }

  _isEmptyLastInput() {
    const lastInput = this._inputComponents[this._inputComponents.length - 1];
    return lastInput.value === ``;
  }

  _onAddButtonClickHandler() {
    if (this._isEmptyLastInput()) {
      return;
    }

    this._renderEmptyInput();
  }

  _onInputHandler() {
    this._onChangeData(this._inputComponents);
    if (this._handler) {
      this._handler();
    }
  }

  _onDeleteHandler() {
    this._onInputHandler();
  }
}
