import {checkPhone} from '../../../utils/common.js';
import AbstractInputComponent from '../abstract-input.js';


const createPhoneInputTemplate = (label, value, isRequired, isDeleteButton) => {
  return (
    `<div class="dropDownList__wrap" data-new="true" data-required="${isRequired}" data-validate="base">
      <label class="dropDownList__label">${label}</label>
      <input class="dropDownList__input" type="phone" name="" value="${value}">
      ${isDeleteButton ?
      `<button class="master__btn master__btn--delete" type="button" title="Удалить">
          <span class="visuallyhidden">Удалить</span>
        </button>` : ``}
    </div>`
  );
};

export default class PhoneInputComponent extends AbstractInputComponent {
  getTemplate() {
    return createPhoneInputTemplate(this._label, this._value, this._isRequired, this._isDeleteButton);
  }

  _validate() {
    const value = this.value;

    if (value.includes(`***`)) {
      this.isValid = true;
      return;
    }

    const phone = checkPhone(value);
    // pattern="\\+7\\s\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]{4}"
    if (phone.length === 11 || phone.length === 12) {
      this.isValid = true;
      return;
    }

    this.isValid = false;
  }

  _onChangeHandler() {
    this._checkDeleteButton();
    this._showDetailError();
  }

  _onInputHandler() {
    this._showDetailError();
  }

  _showDetailError() {
    const phone = checkPhone(this.value);

    if (phone.length !== 11 && phone.length !== 12) {
      const text = `Введите телефон в формате +7 955 555 55 55 или 8 955 555 55 55.`;
      this._renderDetailError(text);
      return;
    }

    this._removeDetailError();
  }
}
