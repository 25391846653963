import {Language, LanguageToEngText} from "../../const";
import {DefaultTitleRegistrationField} from "../../const/registration-field";

export const getPatternRegistrationField = (languageData, nameField, defaultValue) => {
  if (!languageData) {
    return defaultValue;
  }

  const language = languageData.language;

  const name = `${nameField}_pattern`;

  if (language === LanguageToEngText[Language.RU]) {
    return languageData.getData(name) || defaultValue || `${DefaultTitleRegistrationField[name]}`;
  }

  return languageData.getData(name) || `${DefaultTitleRegistrationField[name]} (${language})`;
};
