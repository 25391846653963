export const commonDomains = [
  `mail.ru`,
  `yandex.ru`,
  `gmail.com`,
  `bk.ru`,
  `list.ru`,
  `inbox.ru`,
  `rambler.ru`,
  `icloud.com`,
  `yandex.by`,
  `tut.by`,
  `ya.ru`,
  `lilballerine.ru`,
  `internet.ru`,
  `yahoo.com`,
  `mail.com`,
  `kopeysk-uo.ru`,
  `post.gomel.by`,
  `hotmail.com`,
  `vk.com`,
  `yandex.com`,
  `kreativcentr.ru`,
  `gomelculture.by`,
  `yandex.kz`,
];
