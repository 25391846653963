import {isEmptyArray} from '../../utils/array/is-empty-array/is-empty-array';
import {checkAccess, getIsNoDirector, isEmptyObject} from '../../utils/common';
import PerformanceParsing from './parsing';


export default class PerformanceModel {
  constructor(data) {
    this.performanceId = data.performance_id;
    this.type = `performance`;
    this.performanceTitle = PerformanceParsing.performanceTitle(data);
    this.performanceCid = PerformanceParsing.performanceCid(data);
    this.isBlocked = PerformanceParsing.unblock(data);
    this.unblockPaid = PerformanceParsing.unblockPaid(data);
    this.turnId = PerformanceParsing.performanceTurnId(data);
    this.groupId = PerformanceParsing.performanceGroup(data);
    this.timeStart = PerformanceParsing.performanceTimeStart(data);
    this.beforeText = PerformanceParsing.performanceEntryBefore(data);
    this.afterText = PerformanceParsing.performanceEntryAfter(data);

    this.ageCategory = PerformanceParsing.performanceAgeCategory(data);
    this.nomination = PerformanceParsing.performanceNomination(data);
    this.qualification = PerformanceParsing.performanceQualification(data);

    this.duration = PerformanceParsing.performanceTimeFull(data);
    this.money = PerformanceParsing.performanceMoney(data);

    this.isArchive = PerformanceParsing.performanceArchive(data);
    this.city = PerformanceParsing.performanceCity(data);
    this.cityFull = PerformanceParsing.performanceCityFull(data);
    this.cityId = PerformanceParsing.performanceCityId(data);
    this.cityType = PerformanceParsing.performanceCityType(data);
    this.cityShortType = PerformanceParsing.performanceCityStype(data);
    this.country = PerformanceParsing.performanceCountry(data);
    this.team = PerformanceParsing.performanceTeam(data);
    this.organization = PerformanceParsing.performanceOrganization(data);

    this.directors = PerformanceParsing.performanceDirectorNew(data);
    this.isNoDirector = getIsNoDirector(this.directors); // указание что руководителя нет

    this.directorsData = {
      directors: this.directors,
      isNoDirector: this.isNoDirector, // указание что руководителя нет
    };


    this.playersData = {
      players: PerformanceParsing.performancePlayer(data),
      count: PerformanceParsing.performancePlayerCount(data),
      nameCount: PerformanceParsing.playerCountNameShow(data),
    };
    this.players = PerformanceParsing.performancePlayer(data);
    this.countPlayers = PerformanceParsing.performancePlayerCount(data);

    this.contactPeople = PerformanceParsing.performanceContact(data);

    this.phonogramsData = PerformanceParsing.phonogramsData(data);

    this.phonograms = PerformanceParsing.performancePhonograms(data);
    this.generalPhonogramStatus = PerformanceParsing.phonogramGeneralStatus(data);

    this.files = PerformanceParsing.performanceContent(data);
    this.links = PerformanceParsing.performanceLink(data);

    this.ratings = PerformanceParsing.performanceRating(data);
    this.ratingReferees = PerformanceParsing.performanceAllRating(data); // для запроса "get_rating: all"
    this.ratingSum = PerformanceParsing.performanceRatingSum(data);
    this.refereeRatingSum = PerformanceParsing.refereePerformanceRatingSum(data);

    this.prizeId = PerformanceParsing.performancePrize(data);
    this.prizeManualId = PerformanceParsing.performancePrizeManual(data);
    this.prints = PerformanceParsing.documentPrinted(data);

    this.notes = PerformanceParsing.performanceNote(data);
    this.userField = PerformanceParsing.performanceUserData(data);

    this.realTime = PerformanceParsing.performanceReal(data);

    this.registrator = PerformanceParsing.performanceAddPeople(data);

    this.creationDate = PerformanceParsing.performanceCreationDate(data);

    this.performanceSource = PerformanceParsing.performanceSource(data);
    this.contestRoles = PerformanceParsing.contestRole(data);
    this.contestId = PerformanceParsing.contestId(data);

    this.refereeProgress = PerformanceParsing.performanceReferee(data);

    this.access = PerformanceModel.getAccess(data);
  }

  toRAW() {
    return {};
  }

  static newPerformance(data) {
    return new PerformanceModel(data);
  }

  static parsePerformance(data) {
    if (!data.data[0]) {
      return null;
    }
    return PerformanceModel.newPerformance(data.data[0]);
  }

  // проверка на пустоту инфы от сервера
  // if (isEmptyObj(request.data)) {
  //   debugger;
  //   return null;
  // }

  static parsePerformances(data) {
    if (isEmptyObject(data.data) || isEmptyArray(data.data)) {
      return [];
    }

    return data.data.map((performance) => PerformanceModel.newPerformance(performance));
    // const count = data.performance_count;

    // return {performances, count};
  }

  static getAccess(data) {
    return {
      isBlocked: checkAccess(data.unblock),
      performanceCid: checkAccess(data.performance_cid),
      turnId: checkAccess(data.performance_turn_id),
      group: checkAccess(data.performance_group),
      timeStart: checkAccess(data.performance_time_start),
      beforeText: checkAccess(data.performance_entry),
      afterText: checkAccess(data.performance_entry),
      ageCategory: checkAccess(data.performance_age_category),
      nomination: checkAccess(data.performance_nomination),
      qualification: checkAccess(data.performance_qualification),
      duration: checkAccess(data.performance_time_full),
      performanceTitle: checkAccess(data.performance_title),
      money: checkAccess(data.performance_money),
      isArchive: checkAccess(data.performance_archive),
      city: checkAccess(data.performance_city),
      cityId: checkAccess(data.performance_city_id),
      cityType: checkAccess(data.performance_city_type),
      cityShortType: checkAccess(data.performance_city_stype),
      country: checkAccess(data.performance_country),
      team: checkAccess(data.performance_team_title),
      organization: checkAccess(data.performance_organization_title),
      directors: checkAccess(data.performance_director),
      players: checkAccess(data.performance_player),
      countPlayers: checkAccess(data.performance_player_count),
      contactPeople: checkAccess(data.performance_contact),
      phonograms: checkAccess(data.phonogram_title),
      generalPhonogramStatus: checkAccess(data.performance_phonogram_status),
      files: checkAccess(data.performance_content),
      ratings: checkAccess(data.performance_rating),
      ratingReferees: checkAccess(data.performance_rating),
      ratingSum: checkAccess(data.performance_rating_sum),
      prizeId: checkAccess(data.performance_prize),
      prizeManualId: checkAccess(data.performance_prize_manual),
      // isPrinted: checkAccess(data.document_printed),
      prints: checkAccess(data.document_printed),
      notes: checkAccess(data.performance_note),
      userField: checkAccess(data.performance_user_data),
      realTime: checkAccess(data.performance_real_start),
      registrator: checkAccess(data.performance_add_people),
      creationDate: checkAccess(data.performance_creation_date),
      contestRoles: checkAccess(data.contest_role),
      contestId: checkAccess(data.contest_id),
      refereeProgress: checkAccess(data.performance_referee),
    };
  }

  static clone(data) {
    return new PerformanceModel(data.toRAW());
  }
}
