import AbstractInputComponent from '../abstract-input.js';


const createDateInputTemplate = (label, value, isRequired, minValue, maxValue) => {
  return (
    `<div class="dropDownList__wrap" data-new="true" data-required="${isRequired}" data-validate="base">
      <label class="dropDownList__label">${label}</label>
      <input class="dropDownList__input" type="date" name="" value="${value}" min="${minValue}" max="${maxValue}">
    </div>`
  );
};

export default class DateInputComponent extends AbstractInputComponent {
  getTemplate() {
    return createDateInputTemplate(this._label, this._value, this._isRequired, this._minValue, this._maxValue);
  }

  _onChangeHandler() {
    this.inputElement.addEventListener(`change`, () => this._showDetailError());
  }

  _validate() {
    if (this.value !== `` && this.inputElement.validity.valid) {
      this._removeDetailError();
      this.isValid = true;
      return;
    }

    this.isValid = false;
  }

  _showDetailError() {
    if (!this.inputElement.validity.valid) {
      if (this.inputElement.validity.rangeUnderflow) {
        const text = `Введенная дата не должна быть меньше ${this._minValue}`;
        this._renderDetailError(text);
        return;
      }

      if (this.inputElement.validity.rangeOverflow) {
        const text = `Введенная дата не должна превышать ${this._maxValue}`;
        this._renderDetailError(text);
        return;
      }

      if (this.inputElement.validity.badInput) {
        const text = `Введенная недействительная дата.`;
        this._renderDetailError(text);
        return;
      }
    }

    this._removeDetailError();
  }
}
