import {AliasToShortText, AliasToText} from "../../const";
import AbstractComponent from "../abstract-component";


const createAliasItemTemplate = (alias) => {
  const {title, language} = alias;

  return (
    `<div class="master__wrapTile" title="${AliasToText[language] || language.toUpperCase()}">
      <span class="master__iconTile">${AliasToShortText[language] || language.toUpperCase()}</span>
      <div class="master__tile" style="grid-template-columns: 1fr max-content max-content;">
        <p class="master__peopleText">
          <b>${title}</b>
        </p>
      </div>
    </div>`
  );
};


export default class AliasItemComponent extends AbstractComponent {
  constructor({alias}) {
    super();

    this._alias = alias;
  }

  getTemplate() {
    return createAliasItemTemplate(this._alias, this._isEdit);
  }
}
