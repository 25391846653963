export const UserFieldType = {
  TEXT: `text`,
  DROPDOWN: `dropDown`,
  NUMBER: `number`,
  DATE: `date`,
  DATETIME: `datetime`,
  PEOPLE: `people`,
  FILE: `file`,
  LINK: `link`,
  PHONOGRAM: `phonogram`,
};
