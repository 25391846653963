import EditSvgComponent from "../../component/svg/edit";
import {ButtonTitle} from "../../const";
import {render, RenderPosition} from "../../utils/render";
import ButtonController from "../button";

export default class EditButtonController extends ButtonController {
  constructor(parameters) {
    super(parameters);

    this._className = `modal__btn modal__btn--editSvg`;
    this._id = parameters.id;
    this._prompt = parameters.prompt || ButtonTitle.EDIT;
  }

  render() {
    super.render();

    const svgComponent = new EditSvgComponent();
    render(this._button.getElement(), svgComponent.getElement(), RenderPosition.BEFOREEND);
  }
}
