import {DEFAULT_FULL_NAME} from "./../const";
import Popup, {ContentType, PopupSize} from "../static/popup/popup";
import RadioInputComponent from "../component/input/radio-input/radio-input";
import ParagraphComponent from "../component/base/paragraph";

const BASIC_NAME_TYPES = [
  `surname`,
  `lastname`,
  `middlename`
];

const EngNameTypeToRusNameType = {
  'surname': `Фамилия`,
  'lastname': `Имя`,
  'middlename': `Отчество`
};

const RusNameTypeToEngNameType = {
  'Фамилия': `surname`,
  'Имя': `lastname`,
  'Отчество': `middlename`
};


export default class StatisticNameTypeController {
  constructor({nameTypeData, onParseHandler}) {
    this._nameTypeData = nameTypeData;
    this._onParseHandler = onParseHandler;

    this._names = [];
    this._statistics = [];
    this._summary = Object.assign({}, DEFAULT_FULL_NAME);
    this._checkName = ``;
    this._checkType = ``;

    this._onConfirmButtonClickHandler = this._onConfirmButtonClickHandler.bind(this);
    this._onCheckRadioPopupHandler = this._onCheckRadioPopupHandler.bind(this);
  }

  parseName(name) {
    this._names = name.replace(/\s+/g, ` `).trim().split(` `);
    return this._getStatistic();
  }

  _getStatistic() {
    return this._nameTypeData.getData(this._names)
    .then((data) => {
      this._statistics = data.statistic;
    })
    .then(() => this._analyzeFirstName());
  }

  _getNameStatisticPerType(type, nameStatistic) {
    const results = [];

    for (let name in nameStatistic) {
      for (let nameType in nameStatistic[name]) {
        if (nameType !== type) {
          continue;
        }

        results.push({
          name,
          rating: +nameStatistic[name][nameType],
        });
      }
    }

    return results;
  }

  _parseResponsePerType(statisticData) {
    return BASIC_NAME_TYPES.map((type) => {
      const statisticName = this._getNameStatisticPerType(type, statisticData);

      return {
        type,
        statisticName,
      };
    });
  }

  _setStatistics(statistics) {
    this._statistics = statistics;
  }

  _analyzeFirstName() {
    const firstName = this._statistics[0];
    this._analyzeName(firstName);
  }

  _analyzeName(name) {
    const {title, typeStatistics} = name;

    // проверка на нули
    const isNoRating = this._isNoRating(typeStatistics);

    if (isNoRating) {
      this._analyzeNextName(title);
      return;
    }

    const isRepeatRating = this._isRepeatRating(this._getSortDownByRating(typeStatistics));

    if (isRepeatRating) {
      const repeatingTypes = this._getRepeatTypes(this._getSortDownByRating(typeStatistics));
      const emptyTypes = this._getEmptyTypes(repeatingTypes);

      if (emptyTypes.length > 1) {
        this._renderPopup(title, emptyTypes);
        return;
      }

      this._setNameType(title, emptyTypes[0]);
      this._analyzeNextName(title);
      return;
    }

    const type = this._getMaxRatingType(this._getSortDownByRating(typeStatistics));
    this._setNameType(title, type);

    this._analyzeNextName(title);
  }

  _isRepeatRating(statistics) {
    if (statistics.length === 1) {
      return false;
    }

    return statistics[0].rating === statistics[1].rating;
  }

  _getSortDownByRating(statistics) {
    return statistics.sort((a, b) => b.rating - a.rating);
  }

  _getRepeatTypes(statistics) {
    return statistics
      .filter((it) => it.rating === statistics[0].rating)
      .map((it) => it.type);
  }

  _getMaxRatingType(statistics) {
    return statistics[0].type;
  }

  _getChoiceName(name = ``, options = []) {
    const choiceNameWrap = new RadioInputComponent({
      name,
      options,
    });

    choiceNameWrap.setInputHandler(this._onCheckRadioPopupHandler);

    return choiceNameWrap;
  }

  _getOptionTypes(types) {
    return types.map((type) => {
      return {
        label: EngNameTypeToRusNameType[type],
      };
    });
  }

  _renderPopup(title, types) {
    this._checkName = title;
    this._checkType = ``;

    const options = this._getOptionTypes(types);
    const choiceNameWrap = this._getChoiceName(title, options);

    const textComponent = new ParagraphComponent({
      className: `text text--big text--bold`,
      text: `«${title}» – это?`,
    });

    // const parametersPopup = {
    //   size: `small`,
    //   title: `Уточните`,
    //   fragment: [textComponent.getElement(), choiceNameWrap.getElement()],
    //   button: {
    //     'Подтвердить': [this._onConfirmButtonClickHandler, `close`],
    //   }
    // };

    // let popup = new Popup(parametersPopup);
    // popup.open();

    Popup.showCustom({
      size: PopupSize.SMALL,
      title: `Уточните`,
      contents: [
        // {
        //   type: ContentType.TEXT,
        //   content: `Введите Ваш e-mail, указанный при регистрации. Пароль будет повторно отправлен на Вашу почту.`,
        // },
        // {
        //   type: ContentType.INPUT,
        //   content: this._getEmailInputComponent(),
        // },
        {
          type: ContentType.FRAGMENT,
          content: textComponent.getElement(),
        },
        {
          type: ContentType.FRAGMENT,
          content: choiceNameWrap.getElement(),
        },
        {
          type: ContentType.BUTTONS,
          content: [
            // {
            //   title: `Отмена`,
            //   isClose: true,
            // },
            {
              title: `Подтвердить`,
              onClickHandler: this._onConfirmButtonClickHandler,
              // isRequired: true,
            }
          ],
        }
      ]
    });
  }

  _getRepeatRatings(sortNamesByRating) {
    const maxRating = sortNamesByRating[0].rating;

    return sortNamesByRating
      .filter((name) => name.rating === maxRating)
      .map((name) => name.name);
  }

  _onCheckRadioPopupHandler(type) {
    this._checkType = RusNameTypeToEngNameType[type];
  }

  _isNoRating(statistics) {
    return statistics.every((stat) => stat.rating === 0);
  }

  _getUncheckedTitle() {
    for (let i = 0; i < this._names.length; i++) {
      const name = this._names[i];
      let flag = false;

      for (let type in this._summary) {
        const summaryName = this._summary[type];
        if (summaryName === name) {
          flag = true;
        }
      }

      if (!flag) {
        return name;
      }
    }

    return ``;
  }

  _distributeEmptyNames() {
    BASIC_NAME_TYPES.forEach((nameType) => {
      if (this._summary[nameType] === ``) {
        const uncheckedTitle = this._getUncheckedTitle();
        this._summary[nameType] = uncheckedTitle;
      }
    });
  }

  _renderPopupWarning() {
    Popup.showWarning({
      text: `Проверьте правильность указания фамилии, имени и отчества в соответствующих полях.`,
    });
  }

  _isUnchekedName() {
    const uncheckedName = this._getUncheckedTitle();
    return uncheckedName !== ``;
  }

  _onEndAnalyze() {
    const isUncheckedName = this._isUnchekedName(); // хотя бы одно непроверенное имя

    if (isUncheckedName) {
      this._distributeEmptyNames();
      this._renderPopupWarning();
    }

    this._onParseHandler(this._summary);
    this._clear();
  }

  _clear() {
    this._names = [];
    this._statistics = [];
    this._summary = Object.assign({}, DEFAULT_FULL_NAME);
    this._checkName = ``;
    this._checkType = ``;
  }

  _getNextName(name) {
    const index = this._statistics.findIndex((it) => it.title === name);

    if (index === -1 || !this._statistics[index + 1]) {
      return null;
    }

    return this._statistics[index + 1];
  }

  _analyzeNextName(currentName) {
    const nextName = this._getNextName(currentName);

    if (!nextName) {
      this._onEndAnalyze();
      return;
    }

    this._analyzeName(nextName);
  }

  _setNameType(name, type) {
    if (this._summary[type] !== ``) {
      return;
    }

    this._summary[type] = name;
  }

  _onConfirmButtonClickHandler() {
    this._setNameType(this._checkName, this._checkType);
    this._analyzeNextName(this._checkName);
  }

  _getEmptyTypes(types) {
    return types.filter((type) => this._summary[type] === ``);
  }
}
