import ReplaceSvgComponent from "../../component/svg/replace";
import {ButtonTitle} from "../../const";
import {render, RenderPosition} from "../../utils/render";
import ButtonController from "../button";

export default class ReplaceButtonController extends ButtonController {
  constructor(parameters) {
    super(parameters);

    this._className = `master__btn master__btn--replaceSvg`;
    this._prompt = ButtonTitle.REPLACE;
  }

  render() {
    super.render();

    const svgComponent = new ReplaceSvgComponent();
    render(this._button.getElement(), svgComponent.getElement(), RenderPosition.BEFOREEND);
  }
}
