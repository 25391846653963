import {RegistrationField} from "./registration-filed";

export const CONTEST_CONFIG = {
  name: RegistrationField.CONTEST,
  title: `Мероприятие`,
  setting: {
    isShowAddress: true,
    isShowDateContest: true,
    isShowAffirmationDeadlineContest: true,
    isShowPaymentDeadlineContest: true,
    isShowOrganizationLink: true,
    buttonTitle: `Зарегистрироваться`,
    isShowPattern: false,
    pattern:
    `Для регистрации на мероприятие [[contestTitle]], 
которое состоится [[contestDate]] по адресу [[contestAddress]], 
заполните анкету. 

Регистрация до: [[affirmationDeadline]]. 

Оплата до: [[paymentDeadline]].`,
  },
};
