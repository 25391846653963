import {UserFieldType} from "./user-field-type";

export const USER_FIELD_CODE_PREFIX = {
  [UserFieldType.TEXT]: `UFT_`,
  [UserFieldType.DROPDOWN]: `UFDD_`,
  [UserFieldType.NUMBER]: `UFN_`,
  [UserFieldType.PEOPLE]: `UFP_`,
  [UserFieldType.DATE]: `UFD_`,
  [UserFieldType.DATETIME]: `UFDT_`,
  [UserFieldType.LINK]: `UFL_`,
  [UserFieldType.FILE]: `UFF_`,
  [UserFieldType.PHONOGRAM]: `UFPh_`,
};
