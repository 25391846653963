/**
 * Функция извлечения из адреса почты доменного имени (после знака @)
 * @param {(string|undefined)} email адрес почты.
 * @returns {string} доменное имя
 */

export const getDomain = (email = ``) => {
  if (!email) {
    return ``;
  }

  if (typeof email === `object`) {
    return ``;
  }

  if (!email.includes(`@`)) {
    return ``;
  }

  return email.slice(email.indexOf(`@`) + 1, email.length);
};
