import {commonDomains} from "../../const/common-domains";

// Функция для вычисления сходства Джаро — Винклера
const jaroWinkler = (s1, s2) => {
  let l = 0;
  const jaro = (a, b) => {
    const len1 = a.length;
    const len2 = b.length;

    if (len1 === 0) {
      return len2 === 0 ? 1 : 0;
    }

    const matchDistance = Math.floor(Math.max(len1, len2) / 2) - 1;
    const match1 = Array(len1).fill(false);
    const match2 = Array(len2).fill(false);

    // Find matching characters
    let matches = 0;
    for (let i = 0; i < len1; i++) {
      const start = Math.max(0, i - matchDistance);
      const end = Math.min(i + matchDistance + 1, len2);
      for (let j = start; j < end; j++) {
        if (match2[j]) {
          continue;
        }
        if (a[i] === b[j]) {
          match1[i] = match2[j] = true;
          matches++;
          break;
        }
      }
    }

    if (matches === 0) {
      return 0;
    }

    let t = 0;
    let point = 0;
    for (let i = 0; i < len1; i++) {
      if (match1[i]) {
        while (!match2[point]) {
          point++;
        }
        if (a[i] !== b[point]) {
          t++;
        }
        point++;
      }
    }

    t /= 2;
    return (matches / len1 + matches / len2 + (matches - t) / matches) / 3;
  };

  const jw = jaro(s1, s2);
  const prefixLength = Math.min(4, Math.min(s1.length, s2.length));

  for (let i = 0; i < prefixLength; i++) {
    if (s1[i] === s2[i]) {
      l++;
    } else {
      break;
    }
  }

  return jw + (l * 0.1 * (1 - jw));
};

// Функция для поиска наиболее похожего домена
export const findMostSimilarDomainUsingJaroWinkler = (inputDomain) => {
  let maxSimilarity = -1;
  let mostSimilarDomain = ``;

  // Преобразуем введённый домен в нижний регистр
  const inputDomainLower = inputDomain.toLowerCase();

  // Пройдем по всем доменам и вычислим сходство Джаро — Винклера
  commonDomains.forEach((domain) => {
    const similarity = jaroWinkler(inputDomainLower, domain);

    // Если текущее сходство больше максимального, обновим
    if (similarity > maxSimilarity) {
      maxSimilarity = similarity;
      mostSimilarDomain = domain;
    }
  });

  return mostSimilarDomain;
};
