import {ButtonTitle} from "../../const";
import Popup, {ContentType, PopupSize} from "../../static/popup/popup";
import {checkCommonDomain} from "../check-common-domain/check-common-domain";
import {getDomain} from "../get-domain/get-domain";
// import {findMostSimilarDomain} from "../levenshtein/levenshtein";
import {findMostSimilarDomainUsingJaroWinkler} from "../jaro-winkler/jaro-winkler";


const popupShow = (email) => {
  const currentDomain = getDomain(email);
  const similarDomain = findMostSimilarDomainUsingJaroWinkler(currentDomain);

  return new Promise((resolve) => {
    Popup.showCustom({
      title: `Проверьте адрес почты`,
      size: PopupSize.MEDIUM,
      contents: [

        {
          type: ContentType.TEXT,
          content: `Пожалуйста, проверьте правильность указания адреса электронной почты.`,
        },
        {
          type: ContentType.TEXT,
          content: `Название домена после символа "@" <b>${currentDomain}</b> не совпадает ни с одним из распространенных.`,
        },
        {
          type: ContentType.TEXT,
          content: `Наиболее похожий домен – <b>${similarDomain}</b>`,
        },

        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: `Исправить вручную`,
              onClickHandler: () => resolve({action: `change`}),
              isClose: true,
              isRequired: true,
            },
            {
              title: `Исправить на ${similarDomain}`,
              onClickHandler: () => {
                const newMail = email.slice(0, email.length - currentDomain.length) + similarDomain;
                resolve({action: `similar`, newMail});
              },
              isClose: true,
              isRequired: true,
            },
            {
              title: `Все указано верно`,
              onClickHandler: (data) => resolve({action: `continue`, data}),
              isClose: true
            },
          ],
        }
      ]
    });
  });
};

const popupChange = (email) => {
  return new Promise((resolve) => {
    Popup.showCustom({
      size: PopupSize.SMALL,
      contents: [
        {
          type: ContentType.INPUT_NEW,
          content: {
            type: `text`,
            name: `email`,
            value: email,
          },
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: ButtonTitle.CANCEL,
              onClickHandler: () => resolve(),
              isClose: true,
              isRequired: true,
            },
            {
              title: ButtonTitle.CHANGE,
              onClickHandler: (data) => resolve({data}),
              isClose: true
            },
          ],
        }
      ]
    });
  });
};


export const checkDomainPromise = async (emails = []) => {
  const email = emails[0];

  if (!email || !email.mail) {
    return Promise.resolve();
  }

  const isCheck = checkCommonDomain(getDomain(email.mail));

  if (isCheck) {
    return Promise.resolve();
  }

  return await popupShow(email.mail)
    .then(async ({action, newMail}) => {
      if (action === `similar`) {
        email.mail = newMail;
      }

      if (action === `change`) {
        return await popupChange(email.mail)
        .then(({data}) => {
          email.mail = data.email;
        });
      }

      return Promise.resolve();
    });
};
