import AbstractSmartComponent from '../abstract-smart-component.js';
import editSvg from './../../../svg/edit.svg';

const createEditSvgTemplate = () => {
  return (
    `<svg aria-hidden="true" width="25" height="30">
      <use href="${editSvg}"/>
    </svg>`
  );
};

export default class EditSvgComponent extends AbstractSmartComponent {
  getTemplate() {
    return createEditSvgTemplate();
  }
}
