import API from "../api";
import ButtonComponent from "../component/base/button";
import DivComponent from "../component/base/div";
import ParagraphComponent from "../component/base/paragraph";
import DateInputComponent from "../component/input/date-input/date-input";
import TextInputComponent from "../component/input/text-input/text-input";
import {ButtonTitle, DEFAULT_PEOPLE, ModePeoplePopup, ModeRenderWrap, MultiWrapInputRequiredMode, NameType, NameTypeByRuAccCase, PeopleMode, SearchArea, TerminalPageEnToTerminalPageRus, TERMINAL_PAGES} from "../const";
import NameTypeData from "../data/statistic-name/statistic-name";
import CheckUpperCase from "../static/check-upper-case/check-upper-case";
import Popup, {ContentType, PopupSize} from "../static/popup/popup";
import {copyToClipboard, isEmptyObject, parsePositionsToText} from "../utils/common";
import {isEqualityName} from "../utils/name";
import {remove, render, RenderPosition, replace} from "../utils/render";
import StatisticNameTypeController from "./statistic-name-type";
import EmailMultiInputsController from "./multi-inputs/email";
import PhoneMultiInputsController from "./multi-inputs/phone";
import AliasPeopleItemComponent from "../component/alias-people/alias-people";
import PeopleAliasController from "./alias/people";
import {PeopleParameter} from "../data/people/parameter";
import ButtonController from "./button";
import EditButtonController from "./buttons/edit";
import ReplaceButtonController from "./buttons/replace";
import TrashButtonController from "./buttons/trash";
import {getFullNameText} from "../utils/get-full-name-text/get-full-name-text";
import {checkDomainPromise} from "../utils/check-email/check-email";


export default class PeopleController {
  constructor() {
    this._people = Object.assign({}, DEFAULT_PEOPLE);

    this._addPopup = null;
    this._fragmentsPopupComponent = null;
    this._errorMessageComponent = null;
    this._errorMessageSeacrhModeText = ``;

    this._currentPeople = {};
    this._replacedPeople = {};

    this._modeRenderPhone = ModeRenderWrap.ALL;
    this._modeRenderEmail = ModeRenderWrap.ALL;

    this._popupController = null;
    this._isValidationCheck = true;
    // this._searchDatumPopupComponent = null;
    this._switchModeWrapComponent = null;
    this._leadSearchModeComponent = null;
    this._leadSearchClassName = `text text--center text--red`;
    // this._searchInput = null;

    this._isShowSurname = true;
    this._isShowLastname = true;
    this._isShowMiddlename = true;
    this._isShowDOB = false;
    this._isShowPosition = false;
    this._isShowPhone = true;
    this._isShowEmail = true;
    this._isShowTerminalPagePosition = false;
    this._isMultiWrapInput = false;
    this._isShowAlias = false;
    this._showedAliasComponent = {};

    this._surnameInputLabel = `Фамилия`;
    this._editOrAddPopupText = `Вы хотите заменить человека или изменить его данные`;
    this._toSearchModeText = `поиска человека по ФИО`;
    this._leadSearchModeText = `Начните вводить ФИО и выберите человека из результатов поиска.`;
    // this._errorSearchText = `Введите ФИО человека`;
    // this._notFindText = `+ нет подходящего, добавить нового человека`;


    // this._searchName = ``;
    this._onPopupButtonClickHandler = this._onPopupButtonClickHandler.bind(this);
    this._onSwitchModeHandler = this._onSwitchModeHandler.bind(this);
    this._onNotFindPeopleHandler = this._onNotFindPeopleHandler.bind(this);

    this._onInputSurnameHandler = this._onInputSurnameHandler.bind(this);
    this._onInputLastnameHandler = this._onInputLastnameHandler.bind(this);
    this._onInputMiddlenameHandler = this._onInputMiddlenameHandler.bind(this);
    this._onInputDobHandler = this._onInputDobHandler.bind(this);
    this._onInputPositionHandler = this._onInputPositionHandler.bind(this);
    this._onPositionCheckHandler = this._onPositionCheckHandler.bind(this);
    this._onTerminalRoleCheckPopupHandler = this._onTerminalRoleCheckPopupHandler.bind(this);
    this._onChangePhoneHandler = this._onChangePhoneHandler.bind(this);
    this._onChangeEmailHandler = this._onChangeEmailHandler.bind(this);

    this._onClosePopup = this._onClosePopup.bind(this);

    this._addPeople2 = this._addPeople2.bind(this);
    this._editPeople2 = this._editPeople2.bind(this);
    this._replacePeople2 = this._replacePeople2.bind(this);
    this._onEditItemButtonClickHandler = this._onEditItemButtonClickHandler.bind(this);
    this._onDeleteButtonClickHandler = this._onDeleteButtonClickHandler.bind(this);

    this._onChoiceFoundItemHandler = this._onChoiceFoundItemHandler.bind(this);

    this._onParseHandler = this._onParseHandler.bind(this);

    this._copyAuthLinkToClipboard = this._copyAuthLinkToClipboard.bind(this);

    this._onReplaceButtonClickHandler = this._onReplaceButtonClickHandler.bind(this);


    this._statisticNameTypeController = new StatisticNameTypeController({
      nameTypeData: new NameTypeData({api: new API()}),
      onParseHandler: this._onParseHandler
    });

    this._modePopup = ModePeoplePopup.SEARCH;
    this._mode = `search-edit`; // поиск и редактирование
  }

  // добавление человека
  _addPeople2() { // бывший _onAddPeopleHandler
    // установка режима для человека
    this._people.mode = PeopleMode.ADD;

    // установка режима для отрисовки вплывашки
    this._setSearchModePopup();
    // отрисовка попапа
    this._renderPopup2(this._addPopupTitle, ButtonTitle.ADD);
  }

  _renderPopup2(title = ``, mainButtonTitle = ``) {
    // сохранение текущих значений человека (при добавление значения по дефолту)
    this._currentPeople = Object.assign({}, this._people);

    // получение контроллера попапа
    this._getPopupController(title, mainButtonTitle)
    // отрисовка попавка
    .then(() => this._renderPopupController());
  }

  _getPopupController(title, mainButtonTitle) {
    if (this._popupController) {
      return Promise.resolve();
    }

    this._popupTitle = title;
    this._mainButtonTitle = mainButtonTitle;

    return Popup.getCustom({
      size: PopupSize.MEDIUM,
      title: this._popupTitle,
      contents: [],
      onClosePopupHandler: this._onClosePopup,
    })
    .then((controller) => {
      this._popupController = controller;
    });
  }

  _onClosePopup() {
    this._popupController = null;

    this._switchModeWrapComponent = null;
    this._leadSearchModeComponent = null;
    // this._searchDatumPopupComponent = null;

    this._showedAliasComponent = {};
  }

  _renderPopupController() {
    if (this._modePopup === ModePeoplePopup.SEARCH) {
      this._renderSearchPopup();
    }

    if (this._modePopup === ModePeoplePopup.MANUAL) {
      this._renderManualPopup2();
    }
  }

  _renderSearchPopup() {
    this._popupController.contents = [
      {
        type: ContentType.FRAGMENT,
        content: this._getLeadSearchModeComponent(),
      },
      // {
      //   type: ContentType.CUSTOM,
      //   content: {
      //     controller: new PeopleSearchController({
      //       searchAPI: this._searchAPI,
      //       titleInputName: this._titleInputName,
      //       onTitleCheckHandler: this._onTeamCheckHandler,
      //     }),
      //     name: `people`,
      //     value: this._people.title,
      //     inputId: this._people.peopleId,
      //   },
      // },
      {
        type: ContentType.SEARCH,
        content: {
          area: SearchArea.PEOPLE,
          people: this._people,
          onChoiceHandler: this._onChoiceFoundItemHandler,
          onNotFindPeopleClickHandler: this._onNotFindPeopleHandler,
          isRequired: true,
        },
      },
      {
        type: ContentType.BUTTONS,
        content: [
          {
            title: `Отмена`,
            onClickHandler: () => {},
            isClose: true
          },
          {
            title: this._mainButtonTitle,
            onClickHandler: this._onPopupButtonClickHandler,
            isRequired: true,
            isClose: true,
          },
        ],
      }
    ];

    this._popupController.render();
  }

  _renderManualPopup2() {
    this._popupController.contents = [
      {
        type: ContentType.FRAGMENT,
        content: this._getSwitchModePopupComponent(),
      },
      {
        type: ContentType.INPUT,
        content: this._getSurnameInputComponent(),
      },
      {
        type: ContentType.FRAGMENT,
        content: this._getAliasWrapComponent(NameType.SURNAME),
      },
      {
        type: ContentType.FRAGMENT,
        content: this._getAddAliasButtonComponent(NameType.SURNAME),
      },

      {
        type: ContentType.INPUT,
        content: this._getLastnameInputComponent(),
      },
      {
        type: ContentType.FRAGMENT,
        content: this._getAliasWrapComponent(NameType.LASTNAME),
      },
      {
        type: ContentType.FRAGMENT,
        content: this._getAddAliasButtonComponent(NameType.LASTNAME),
      },

      {
        type: ContentType.INPUT,
        content: this._getMiddlenameInputComponent(),
      },
      {
        type: ContentType.FRAGMENT,
        content: this._getAliasWrapComponent(NameType.MIDDLENAME),
      },
      {
        type: ContentType.FRAGMENT,
        content: this._getAddAliasButtonComponent(NameType.MIDDLENAME),
      },

      {
        type: ContentType.INPUT,
        content: this._getDobInputComponent(),
      },
      {
        type: ContentType.INPUT,
        content: this._getPositionInputComponent(),
      },
      {
        type: ContentType.INPUT,
        content: this._getTerminalRoleInputComponent(),
      },
      {
        type: ContentType.MULTI_INPUT,
        content: this._getPhoneMultiInputComponent(),
      },
      {
        type: ContentType.MULTI_INPUT,
        content: this._getEmailMultiInputComponent(),
      },
      {
        type: ContentType.BUTTONS,
        content: [
          {
            title: ButtonTitle.CANCEL,
            onClickHandler: () => {},
            isClose: true
          },
          {
            title: this._mainButtonTitle,
            onClickHandler: this._onPopupButtonClickHandler,
            isRequired: true,
            isClose: true
          },
        ],
      }
    ];

    this._popupController.render();
  }

  _setSearchModePopup() {
    this._modePopup = ModePeoplePopup.SEARCH;
  }

  _setManualModePopup() {
    this._modePopup = ModePeoplePopup.MANUAL;
  }

  _getSwitchModePopupComponent() {
    if (!this._switchModeWrapComponent) {
      this._switchModeWrapComponent = new DivComponent(`master__wrapAddPlayer`);

      if (this._people.mode !== PeopleMode.EDIT) {
        const textSwitchModeComponent = new ParagraphComponent({
          className: `text`,
          text: `Перейти в режим`,
        });
        render(this._switchModeWrapComponent.getElement(), textSwitchModeComponent.getElement(), RenderPosition.BEFOREEND);

        const addNewPeopleButtonComponent = new ButtonComponent({
          className: `modal__button modal__button--asLink`,
          title: this._toSearchModeText,
        });
        render(this._switchModeWrapComponent.getElement(), addNewPeopleButtonComponent.getElement(), RenderPosition.BEFOREEND);
        addNewPeopleButtonComponent.setClickHandler(this._onSwitchModeHandler);
      }
    }

    return this._switchModeWrapComponent.getElement();
  }

  _getLeadSearchModeComponent() {
    if (!this._leadSearchModeComponent) {
      this._leadSearchModeComponent = new ParagraphComponent({
        className: this._leadSearchClassName,
        text: this._leadSearchModeText,
      });
    }

    return this._leadSearchModeComponent.getElement();
  }

  _getAddAliasButtonComponent(nameType) {
    const component = new DivComponent(``);
    if (this._isShowAlias) {
      new ButtonController({
        container: component.getElement(),
        className: `modal__button modal__button--asLink`,
        title: `+ Добавить или отредактировать ${NameTypeByRuAccCase[nameType]} на другом языке`,
        onClickHandler: () => {
          new PeopleAliasController({
            aliasAPI: this._aliasAPI,
            people: this._people,
            nameType,
            onAliasChange: (aliases) => {
              this._updateAliasComponent(aliases);
            },
          }).renderAddPopup();
        }
      }).render();
    }

    return component.getElement();
  }

  _updateAliasComponent() {
    return this._peopleData.getOneData({
      peopleId: this._people.peopleId,
      parameters: [
        PeopleParameter.ALIAS,
      ]
    })
    .then((people) => {
      this._people.aliases = people.aliases;
    })
    .then(() => {
      this._renderAliasComponent(`surname`);
      this._renderAliasComponent(`lastname`);
      this._renderAliasComponent(`middlename`);
    });
  }

  _getSurnameInputComponent() {
    if (!this._isShowSurname) {
      return null;
    }
    const input = new TextInputComponent({
      label: this._surnameInputLabel,
      isRequired: this._isSurnameWrapRequired,
      value: this._people.surname,
      mode: this._isSurnameWrapRequired ? `active` : `passive`,
    });


    input.setInputHandler(this._onInputSurnameHandler);
    input.setChangeHandler(this._onInputSurnameHandler);
    return input;
  }

  _getAliasWrapComponent(mode) {
    const wrap = new DivComponent(``);
    if (this._isShowAlias) {
      const component = this._renderAliasComponent(mode, wrap.getElement());
      this._showedAliasComponent[mode] = component;
    }

    return wrap.getElement();
  }

  _renderAliasComponent(mode, container = null) {
    const oldComponent = this._showedAliasComponent[mode];
    const component = new AliasPeopleItemComponent(mode, this._people.aliases);

    if (oldComponent) {
      replace(component, oldComponent);
    } else {
      render(container, component.getElement(), RenderPosition.BEFOREEND);
    }

    return component;
  }

  _getLastnameInputComponent() {
    if (!this._isShowLastname) {
      return null;
    }

    const input = new TextInputComponent({
      label: `Имя`,
      isRequired: this._isLastnameWrapRequired,
      value: this._people.lastname,
      mode: this._isLastnameWrapRequired ? `active` : `passive`
    });

    input.setInputHandler(this._onInputLastnameHandler);
    input.setChangeHandler(this._onInputLastnameHandler);
    return input;
  }

  _getMiddlenameInputComponent() {
    if (!this._isShowMiddlename) {
      return null;
    }

    const input = new TextInputComponent({
      label: `Отчество`,
      isRequired: this._isMiddlenameWrapRequired,
      value: this._people.middlename,
      mode: this._isMiddlenameWrapRequired ? `active` : `passive`
    });

    input.setInputHandler(this._onInputMiddlenameHandler);
    input.setChangeHandler(this._onInputMiddlenameHandler);
    return input;
  }

  _getDobInputComponent(value = ``) {
    if (!this._isShowDOB) {
      return null;
    }

    const input = new DateInputComponent({
      label: `Дата рождения`,
      isRequired: this._isDobWrapRequired,
      value,
      mode: this._isDobWrapRequired ? `active` : `passive`
    });

    input.setInputHandler(this._onInputDobHandler);

    return input;
  }

  _getPhoneMultiInputComponent() {
    if (!this._isShowPhone) {
      return null;
    }

    const phones = this._people.phones || [];

    const phoneWrap = new PhoneMultiInputsController({
      phones,
      // addButtonTitle: `Добавить еще телефон`,
      isShowAddButton: this._isMultiWrapInput,
      isRequired: this._isPhoneWrapRequired,
      // onAddButtonClickHandler: this._onAddPhoneInputPopupHandler,
      inputMode: this._isPhoneWrapRequired ? `active` : `passive`,
      requiredMode: this._isPhoneWrapRequired ? MultiWrapInputRequiredMode.ONE : MultiWrapInputRequiredMode.NONE,
      modeRender: this._modeRenderPhone,
      onChangeData: this._onChangePhoneHandler,
    });

    return phoneWrap;
  }

  _getEmailMultiInputComponent() {
    if (!this._isShowEmail) {
      return null;
    }

    const emailWrap = new EmailMultiInputsController({
      emails: this._people.emails || [],
      isShowAddButton: this._isMultiWrapInput,
      isRequired: this._isEmailWrapRequired,
      inputMode: this._isEmailWrapRequired ? `active` : `passive`,
      requiredMode: this._isEmailWrapRequired ? MultiWrapInputRequiredMode.ONE : MultiWrapInputRequiredMode.NONE,
      modeRender: this._modeRenderEmail,
      onChangeData: this._onChangeEmailHandler,
    });

    return emailWrap;
  }

  _getPositionInputComponent() {
    if (!this._isShowPosition) {
      return null;
    }

    const input = new TextInputComponent({
      label: `Должность`,
      isRequired: this._isPositionWrapRequired,
      value: this._people.positions ? parsePositionsToText(this._people.positions) : ``,
      values: this._people.positions ? this._people.positions.map((position) => position.title) : [],
      mode: this._isPositionWrapRequired ? `active` : `passive`,
      dropDownItems: this._getPositionItems(),
      onDropDownItemCheck: this._onPositionCheckHandler,
      isPopupDropDown: true,
      isMultiChoiceDropDown: true,
      isPositions: true,
      isReadOnly: this._isReadOnlyPositions,
    });

    input.setInputHandler(this._onInputPositionHandler);
    input.setChangeHandler(this._onInputPositionHandler);

    return input;
  }

  _getPositionItems() {
    if (!this._contestPositions) {
      return [];
    }

    return this._contestPositions.map((position) => {
      return {
        id: position.positionId,
        text: position.title
      };
    });
  }

  _getTerminalRoleInputComponent() {
    if (!this._isShowTerminalPagePosition) {
      return null;
    }

    this._terminalRoleWrap = new TextInputComponent({
      label: `Роль`,
      isRequired: this._isTerminalRoleWrapRequired,
      value: this._people.page ? TerminalPageEnToTerminalPageRus[this._people.page] : ``,
      // value: this._people.role ? TerminalPageEnToTerminalPageRus[this._people.role] : ``,
      mode: this._isTerminalRoleWrapRequired ? `active` : `passive`,
      dropDownItems: TERMINAL_PAGES,
      onDropDownItemCheck: this._onTerminalRoleCheckPopupHandler,
      isReadOnly: true,
      isPopupDropDown: true,
    });

    return this._terminalRoleWrap;
  }

  _renderEditButton(container) {
    new EditButtonController({
      container,
      onClickHandler: this._onEditItemButtonClickHandler,
      withClick: this._checkIsBlocked,
    }).render();
  }

  _renderReplaceButton(container) {
    new ReplaceButtonController({
      container,
      onClickHandler: this._onReplaceButtonClickHandler,
      withClick: this._checkIsBlocked,
    }).render();
  }

  _renderDeleteButton(container) {
    new TrashButtonController({
      container,
      onClickHandler: this._onDeleteButtonClickHandler,
      withClick: this._checkIsBlocked,
    }).render();
  }

  _onDeleteButtonClickHandler() {}

  _onReplaceButtonClickHandler() {
    this._replacePeople2();
  }

  _onInputSurnameHandler(value) {
    this._people.surname = value;
  }

  _onInputLastnameHandler(value) {
    this._people.lastname = value;
  }

  _onInputMiddlenameHandler(value) {
    this._people.middlename = value;
  }

  _onInputDobHandler(value) {
    this._people.dob = value;
  }

  _onInputPositionHandler(value) {
    this._people.positions = value.split(`,`).map((it) => it.trim());
  }

  _onPositionCheckHandler() {}

  _onTerminalRoleCheckPopupHandler(value) {
    this._people.role = value;
  }

  _onChangePhoneHandler(phones) {
    this._people.phones = phones.map((item) => {
      return {
        phone: item.value,
        phoneId: item.inputId
      };
    });
  }

  _onChangeEmailHandler(emails) {
    this._people.emails = emails.map((item) => {
      return {
        mail: item.value,
        mailId: item.inputId
      };
    });
  }

  _setPeopleName(data) {
    this._people.peopleId = data.peopleId;
    this._people.surname = data.surname;
    this._people.lastname = data.lastname;
    this._people.middlename = data.middlename;
  }

  _getPeoplePhoneEmail() {
    return this._peopleData.getOneData({
      peopleId: this._people.peopleId,
      parameters: [
        PeopleParameter.EMAIL,
        PeopleParameter.PHONE,
      ]
    })
    .then((people) => {
      this._people.emails = people.emails || [];
      this._people.phones = people.phones || [];
    });
  }

  _onChoiceFoundItemHandler(foundPeople = {}) {
    if (isEmptyObject(foundPeople)) {
      return;
    }

    this._setManualModePopup();

    if (this._mode === `search`) { // ???
      return;
    }

    this._setPeopleName(foundPeople);

    this._getPeoplePhoneEmail()
    .then(() => this._renderPopup2());
  }

  // логика если человек в поиске не найден
  // - установка ручного режима
  // - получение статистики имени
  // - при получение ответа от статистики - отрисовка попапа

  _onNotFindPeopleHandler(name) {
    this._setManualModePopup();
    this._people.peopleId = ``;

    this._statisticNameTypeController.parseName(name);
  }

  _onParseHandler(name) {
    this._people.surname = name.surname;
    this._people.lastname = name.lastname;
    this._people.middlename = name.middlename;

    this._renderPopup2();
  }

  _onSwitchModeHandler() {
    this._setSearchModePopup();

    // this._setName();
    this._renderPopup2();
  }

  // вопрошайка редактировать или заменить
  _renderConfirmEditOrAdd({onReplaceHandler, onEditButton}) {
    Popup.showCustom({
      size: PopupSize.SMALL,
      contents: [
        {
          type: ContentType.TEXT,
          content: `${this._editOrAddPopupText} – <b>${getFullNameText(this._people)}</b>?`,
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: ButtonTitle.REPLACE,
              onClickHandler: onReplaceHandler,
              isClose: true
            },
            {
              title: ButtonTitle.EDIT,
              onClickHandler: onEditButton,
              isClose: true
            },
          ],
        }
      ]
    });
  }

  _onEditItemButtonClickHandler() {
    this._renderConfirmEditOrAdd({
      onReplaceHandler: this._replacePeople2,
      onEditButton: this._editPeople2,
    });
  }

  // клика на редактировании
  // - режим пиплу - редактировать
  // - установка ручного режима попапу
  // - отрисовка
  _editPeople2() {
    this._people.mode = PeopleMode.EDIT;

    this._setManualModePopup();
    this._renderPopup2(this._editPopupTitle, ButtonTitle.CHANGE);
  }

  _setDefaultPeople() {
    this._people = Object.assign({}, DEFAULT_PEOPLE);
  }

  _replacePeople2() {
    this._replacedPeople = Object.assign({}, this._people);
    this._setDefaultPeople();

    this._people.mode = PeopleMode.REPLACE;

    this._setSearchModePopup();
    this._renderPopup2(this._replacePopupTitle, ButtonTitle.REPLACE);
  }

  _onPopupButtonClickHandler() {
    if (!this._people.surname) {
      return;
    }

    if (this._people.mode === PeopleMode.EDIT) {
      this._checkUpperName()
      .then(() => this._checkDomainEmail())
      .then(() => this._edit())
      .then(() => this._onEditPeopleHandler2(this._currentPeople, this._people))
      .catch((error) => {
        console.log(error);
        this._onEditPeopleHandler2(this._currentPeople, this._people);
      });
      return;
    }

    if (this._people.mode === PeopleMode.REPLACE) {
      this._checkUpperName()
      .then(() => this._checkDomainEmail())
      .then(() => this._add())
      .then(() => this._onReplacePeopleHandler2(this._replacedPeople, this._people))
      .catch(() => this._onReplacePeopleHandler2(this._replacedPeople, this._people));
      return;
    }

    this._checkUpperName()
    .then(() => this._checkDomainEmail())
    .then(() => this._add())
    .then(() => this._onAddPeopleHandler2(this._people))
    .catch(() => this._onAddPeopleHandler2(this._people));
  }

  _add() {
    return this._setPeople(this._people, this._currentPeople)
    .then((peopleId) => {
      this._people.peopleId = peopleId;
    })
    .then(() => this._setEmails(this._people, this._currentPeople))
    .then(() => this._setPhones(this._people, this._currentPeople));
  }

  _edit() {
    return this._setPeople(this._people, this._currentPeople)
    .then(() => this._setEmails(this._people, this._currentPeople))
    .then(() => this._setPhones(this._people, this._currentPeople));
  }

  _setPeople(newPeople, currentPeople) {
    if (!newPeople.peopleId) {
      return this._peopleData.addPeople(newPeople);
    }

    if (currentPeople && !isEqualityName(newPeople, currentPeople)) {
      return this._peopleData.changeName(newPeople);
    }

    return Promise.resolve(newPeople.peopleId);
  }

  _getState(currentData, newData) {
    if (newData === `` && currentData === ``) {
      return `empty`;
    }

    if (newData === ``) {
      return `delete`;
    }

    if (currentData === newData) {
      return `ok`;
    }

    if (currentData === `` && newData !== ``) {
      return `add`;
    }

    if (newData.includes(`*`)) {
      return `error`;
    }

    return `change`;
  }

  _setEmails(newDataPeople, currentDataPeople) {
    const currentEmails = currentDataPeople.emails;
    const newEmails = newDataPeople.emails;

    const count = Math.max(currentEmails.length, newEmails.length);

    return Promise.all(
        new Array(count)
          .fill(``)
          .map((it, i) => {
            const currentEmail = currentEmails[i] ? currentEmails[i].mail : ``;
            const newEmail = newEmails[i] ? newEmails[i].mail : ``;

            const state = this._getState(currentEmail, newEmail);

            if (state === `add`) {
              return this._peopleData.addEmail(newDataPeople.peopleId, newEmail)
              .then((mailId) => {
                newEmails[i].mailId = mailId;
              });
            }

            if (state === `change`) {
              return this._peopleData.changeEmail(newDataPeople.peopleId, currentEmail, newEmail);
            }

            if (state === `delete`) {
              return this._peopleData.deleteEmail(newDataPeople.peopleId, currentEmail);
            }

            return Promise.resolve();
          })
    );
  }

  _setPhones(newPeople, currentPeople) {
    const currentPhones = currentPeople.phones;
    const newPhones = newPeople.phones;

    const count = Math.max(currentPhones.length, newPhones.length);

    return new Array(count)
      .fill(``)
      .map((it, i) => {
        const currentPhone = currentPhones[i] ? currentPhones[i].phone : ``;
        const newPhone = newPhones[i] ? newPhones[i].phone : ``;

        const state = this._getState(currentPhone, newPhone);

        if (state === `add`) {
          return this._peopleData.addPhone(newPeople.peopleId, newPhone);
        }

        if (state === `change`) {
          return this._peopleData.changePhone(newPeople.peopleId, currentPhone, newPhone);
        }

        if (state === `delete`) {
          return this._peopleData.deletePhone(newPeople.peopleId, currentPhone);
        }

        return Promise.resolve();
      });
  }

  _checkUpperName() {
    return CheckUpperCase.checkUpperName(this._people);
  }

  _checkDomainEmail() {
    return checkDomainPromise(this._people.emails);
  }

  _copyAuthLinkToClipboard(authLink) {
    copyToClipboard(`${window.location.origin}${authLink}`);
  }

  static copyAuthLinkToClipboard(authLink) {
    copyToClipboard(`${window.location.origin}${authLink}`);
  }


  //  ....///////////////

  searchPeople() {
    this._mode = `search`; // только поиск, без редактирования
    this._onAddPeopleHandler();
  }

  _remove() {
    remove(this._itemComponent);
  }
}
