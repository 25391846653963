
import {NameTypeByRuAccCase} from "../../const";
import Popup, {ContentType, PopupSize} from "../popup/popup";

const popupUpperName = (name, typeName = ``) => {
  if (name === `` || name[0].toUpperCase() === name[0]) {
    return Promise.resolve(`no`);
  }

  return new Promise((resolve) => {
    Popup.showCustom({
      size: PopupSize.SMALL,
      contents: [
        {
          type: ContentType.TEXT,
          content: `Вы указали с маленькой буквы ${NameTypeByRuAccCase[typeName]} <b>${name}</b>.`,
        },
        {
          type: ContentType.TEXT,
          content: `Cохранить с большой буквы <b>${name[0].toUpperCase()}${name.slice(1)}</b>?`,
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: `Да`,
              onClickHandler: () => resolve(`yes`),
              isClose: true
            },
            {
              title: `Нет`,
              onClickHandler: () => resolve(`no`),
              isClose: true
            },
          ],
        }
      ]
    });
  });
};

export default class CheckUpperCase {
  static checkUpperName(people) {
    const surname = people.surname;
    const lastname = people.lastname;
    const middlename = people.middlename;

    const surnameResolve = () => {
      people.surname = `${surname[0].toUpperCase()}${surname.slice(1)}`;
    };

    const lastnameResolve = () => {
      people.lastname = `${lastname[0].toUpperCase()}${lastname.slice(1)}`;
    };

    const middlenameResolve = () => {
      people.middlename = `${middlename[0].toUpperCase()}${middlename.slice(1)}`;
    };

    return popupUpperName(surname, `surname`)
    .then((response) => {
      if (response === `yes`) {
        surnameResolve();
      }
    })
    .then(() => popupUpperName(lastname, `lastname`))
    .then((response) => {
      if (response === `yes`) {
        lastnameResolve();
      }
    })
    .then(() => popupUpperName(middlename, `middlename`))
    .then((response) => {
      if (response === `yes`) {
        middlenameResolve();
      }
    });
  }
}
