import StatisticNameParsing from './parsing';


export default class StatisticNameModel {
  constructor(data) {
    this.statistic = StatisticNameParsing.peopleNameType(data);
  }

  static newStatisticName(data) {
    return new StatisticNameModel(data);
  }

  static parse(data) {
    return StatisticNameModel.newStatisticName(data);
  }
}
