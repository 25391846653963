import {isEmptyArray} from "../array/is-empty-array/is-empty-array";
import {isEmptyObject} from "../common";

/**
 * Функция проверки аргумента на пустоту
 * @param {any[]|object|undefined|null|string} arg проверяемый аргумент
 * @returns {boolean} булево значение true/false.
 * Примечание: переданный 0 (ноль) НЕ является пустотой
 */

export const isEmpty = (arg) => {
  if (arg === 0) {
    return false;
  }

  return arg === `` || arg === undefined || arg === null
    || Array.isArray(arg) && isEmptyArray(arg) || isEmptyObject(arg);
};
