import API from "../../../api";
import DivComponent from "../../../component/base/div";
import {DEFAULT_CITY} from "../../../const/default-city";
import OrganizationData from "../../../data/organization/organization";
import {OrganizationParameter} from "../../../data/organization/parameter";
import SearchAPI from "../../../data/search";
import {TeamParameter} from "../../../data/team/parameter";
import TeamData from "../../../data/team/team";
import {getTextBlocksRegistrationField} from "../../../utils/get-text-blocks/get-text-blocks";
import {getTitleRegistrationField} from "../../../utils/get-title-registration-field/get-title-registration-field";
import {RenderPosition, render, replace} from "../../../utils/render";
import CitySearchController from "../../search/city";


export default class CityController {
  constructor({container, isShow = true, city = Object.assign({}, DEFAULT_CITY), tabId, nameField, setting, languageController, onCityPerformanceChange}) {
    this._container = container;
    this._api = new API();
    this._city = city;

    this._tabId = tabId;
    this._setting = setting;
    this._languageController = languageController;
    this._teamAPI = new TeamData({api: this._api});
    this._organizationAPI = new OrganizationData({api: this._api});

    this._title = this._getTitleValue();
    this._isRequired = setting.isRequired;
    this._placeholder = setting.placeholder;
    this._nameField = nameField;

    this._isValid = !this._isRequired;

    this._noFindedCityText = this._getTextBlockLanguage(`0`);
    this._googlePopupTitle = this._getTextBlockLanguage(`1`);
    this._errorCity = this._getTextBlockLanguage(`2`);

    this._inputComponent = null;

    this._cityPopup = null;

    this._onCityPerformanceChange = onCityPerformanceChange;

    this._contentComponent = null;

    this._isShow = isShow;
  }

  get registrationData() {
    return this._city;
  }

  get isShow() {
    return this._isShow;
  }

  set isShow(data) {
    this._isShow = data;
  }

  show() {
    this._contentComponent.show();
    this.isShow = true;
  }

  hide() {
    this._contentComponent.hide();
    this.isShow = false;
  }

  _getTitleValue() {
    return getTitleRegistrationField(this._languageController, `city`, this._setting.title);
  }

  _getTextBlockLanguage(index) {
    return getTextBlocksRegistrationField(this._languageController, `city`, index, (this._setting && this._setting.textBlocks && this._setting.textBlocks[index]) ? this._setting.textBlocks[index].text : undefined);
  }

  get title() {
    return this._title;
  }

  get isValid() {
    return this._isValid;
  }

  _update() {
    if (!this._contentComponent) {
      return;
    }

    this.render();
  }

  render(container) {
    if (container) {
      this._container = container;
      this._contentComponent = null;
    }
    const oldContentComponent = this._contentComponent;
    this._contentComponent = new DivComponent(`form-registration__fieldCity`);

    this._renderCity(this._contentComponent.getElement());

    if (oldContentComponent) {
      replace(this._contentComponent, oldContentComponent);
    } else {
      render(this._container, this._contentComponent.getElement(), RenderPosition.BEFOREEND);
    }
    this._validate();
  }

  setTeamCity({teamId, city}) {
    if (!teamId || !city) {
      return;
    }

    this._teamAPI.getOneData({
      teamId,
      parameters: [
        TeamParameter.CITY_ID,
      ],
    })
    .then((data) => {
      this._setCity({city, googleId: data.cityId});
      this.render();

      this._onDataChange();
    });
  }

  setOrganizationCity({organizationId, city}) {
    if (!organizationId || !city) {
      return;
    }

    this._organizationAPI.getOneData({
      organizationId,
      parameters: [
        OrganizationParameter.CITY_ID,
      ],
    })
    .then((data) => {
      this._setCity({city, googleId: data.cityId});
      this.render();

      this._onDataChange();
    });
  }

  showValidate() {
    // this._inputComponent.mode = `active`;
  }

  clearData() {
    this._setCity(DEFAULT_CITY);
    this._update();

    this._onDataChange();
  }

  _renderCity(container) {
    this._cityController = new CitySearchController({
      searchAPI: new SearchAPI({api: this._api}),
      titleInputName: this._title,
      isRequired: this._isRequired,
      placeholder: this._placeholder,
      setting: {
        // titleInputName: this._title,
        noFindedCityText: this._noFindedCityText,
        googlePopupTitle: this._googlePopupTitle,
        errorCity: this._errorCity,
      },
      onTitleCheckHandler: (data) => {
        this._city = data;
        this._onDataChange();
      },
    });

    this._cityController.render(container, this._city.city, this._city.cityId);
  }

  _setCity(data) {
    this._city = Object.assign({}, data);
    this._city.isCityText = false;
  }

  _onDataChange() {
    this._validate();
    this._onCityPerformanceChange(this._city, this._tabId, this._nameField, this._nameField);
  }

  _validate() {
    if (!this._isRequired) {
      return;
    }

    if (!this._cityController) {
      return;
    }
    this._isValid = this._cityController.isValid;
  }
}
