import DivComponent from "../component/base/div";
import ParagraphComponent from "../component/base/paragraph";
import TextInputComponent from "../component/input/text-input/text-input";
import {Case, CasesToText, Language, LanguageToRusText} from "../const";
import {debounce} from "../utils/debounce";
import {render, RenderPosition, replace} from "../utils/render";


export default class CaseController {
  constructor() {
    this._caseData = [];
    this._wrapComponent = null;

    this._isValid = true;
  }

  show(language) {
    this._update(language);
  }

  get isValid() {
    return this._isValid;
  }

  showValidate() {

    // if (this._mode === `active`) {
    //   if (!this._isRequired) {
    //     this.getElement().dataset.validate = `ok`;
    //   } else {
    //     this.getElement().dataset.validate = (this._isValid) ? `ok` : `error`;
    //   }
    // }

  }

  onChangeValue(handler) {
    // this._onChangeValue = handler;
  }

  get errorValidateText() {
    return `Падежи`;
  }

  render(container, data) {
    this._caseData = data;

    this._wrapComponent = new DivComponent(`case`);
    render(container, this._wrapComponent.getElement(), RenderPosition.BEFOREEND);
  }

  _update(language) {
    const oldComponent = this._wrapComponent;
    this._wrapComponent = new DivComponent(`case`);

    if (language === Language.RU) {
      this._renderTitle();

      Object.keys(Case).forEach((caseName) => {
        this._renderRuLanguageInput(Case[caseName]);
      });
    } else {
      this._renderOtherLanguageInput(language);
    }

    replace(this._wrapComponent, oldComponent);
  }

  _renderTitle() {
    const title = new ParagraphComponent({
      className: `text text--bold`,
      text: `Падежи:`
    });

    render(this._wrapComponent.getElement(), title.getElement(), RenderPosition.BEFOREEND);
  }

  _getCaseTitle(caseName) {
    const caseItem = this._caseData.find((data) => data.language === caseName);

    return caseItem ? caseItem.title : ``;
  }

  _renderRuLanguageInput(caseName) {
    const input = new TextInputComponent({
      label: CasesToText[caseName],
      value: this._getCaseTitle(caseName),
      // isRequired: this._isRequired,
    });

    render(this._wrapComponent.getElement(), input.getElement(), RenderPosition.BEFOREEND);


    input.setInputHandler(debounce((value) => {
      const caseItem = this._caseData.find((it) => it.language === caseName);

      if (!caseItem) {
        this._caseData.push({
          language: caseName,
          title: value,
        });
      } else {
        caseItem.title = value;
      }
    }));
  }

  _renderOtherLanguageInput(language) {
    const input = new TextInputComponent({
      label: LanguageToRusText[language],
      value: this._getCaseTitle(language),
      // isRequired: this._isRequired,
    });

    render(this._wrapComponent.getElement(), input.getElement(), RenderPosition.BEFOREEND);


    input.setInputHandler(debounce((value) => {
      const caseItem = this._caseData.find((it) => it.language === language);

      if (!caseItem) {
        this._caseData.push({
          language,
          title: value,
        });
      } else {
        caseItem.title = value;
      }
    }));
  }
}
