import AbstractSmartComponent from '../abstract-smart-component.js';
import copySvg from './../../../svg/copy.svg';

const createCopyLinkSvgTemplate = () => {
  return (
    `<svg aria-hidden="true" width="30" height="30">
      <use class="" href="${copySvg}"/>
    </svg>`
  );
};

export default class CopyLinkSvgComponent extends AbstractSmartComponent {
  getTemplate() {
    return createCopyLinkSvgTemplate();
  }
}
