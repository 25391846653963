import {AUTH_TEXT_BLOCKS} from "../auth-text-blocks";
import {RegistrationField} from "./registration-filed";

export const AUTHORIZATION_CONFIG = {
  name: RegistrationField.AUTHORIZATION,
  title: `Представьтесь`,
  setting: {
    isShowMiddlename: true,
    isMiddlenameRequired: true,
    textBlocks: AUTH_TEXT_BLOCKS,
    popup: {
      name: `Всплывающее окно после авторизации`,
      title: `Приятно познакомиться!`,
      text: `Организатор получил Ваши данные.

Вам создан личный кабинет. Логин и пароль для входа отправлены на указанный адрес электронной почты.
      
<i>А теперь  расскажите нам самое главное – детали Вашего выступления! </i>`,
    },
    popupConvention: {
      name: `Всплывающее окно «Согласие на обработку персональных данных»`,
      title: ``,
      isPattern: true,
      text: `Нажимая кнопку «Далее», я:<br>
      – принимаю условия <a href="[[contest_rules_link]]" target="_blank">Положения мероприятия</a> «[[contest_title]]»,<br>
      – при этом я даю <a href="[[privacy_policy_ptt]]" target="_blank">Согласие на обработку персональных данных</a> организатору мероприятия,`,
    },
  }
};
