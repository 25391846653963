import {isEmptyArray} from "../../utils/array/is-empty-array/is-empty-array";
import {isEmptyObject} from "../../utils/common";

export const setStartPointForRegistration = ({phonograms = [], startPoint = {}}, phonogramAPI) => {
  if (isEmptyArray(phonograms) || !phonograms[0].phonogramId || !startPoint || isEmptyObject(startPoint) || !startPoint.startPointId || startPoint.startPointId === `none`) {
    return Promise.resolve(`skip`);
  }
  return phonogramAPI.setStartWithPoint(phonograms[0].phonogramId, startPoint.startPointId)
  .catch();
};
