import {ButtonTitle, DEFAULT_PEOPLE, NameType} from "../../const";
import Popup, {ContentType, PopupSize} from "../../static/popup/popup";
import {deepCopyObject} from "../../utils/common";
import AliasController from "../alias";
import PeopleCaseController from "../case-peope";

export default class PeopleAliasController extends AliasController {
  constructor({container = null, aliasAPI, people = Object.assign({}, DEFAULT_PEOPLE), nameType, onAliasChange}) {
    super();

    this._container = container;
    this._aliasAPI = aliasAPI;
    this._people = people;
    this._nameType = nameType;
    this._onAliasChange = onAliasChange;

    this._addButtonTitle = `+ Добавить или отредактировать имя на другом языке`;
  }

  get aliases() {
    return this._people.aliases || [];
  }

  render() {

  }

  renderAddButton() {
    this._renderAddButton();
  }

  _renderAddPopup() { // вынести в index.js
    // if (!isTT()) {
    //   this._renderAddPopup2();
    //   return;
    // }

    Popup.showCustom({
      size: PopupSize.MEDIUM,
      title: `Задать написание на другом языке`,
      onClosePopupHandler: () => {
        this._addPopupController = null;
      },
      contents: [
        this._getLanguageContent(),
        this._getCaseContent(),
        {
          type: ContentType.TEXT,
          content: `<b>RU</b>: ${this._people.surname}`,
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: ButtonTitle.CANCEL,
              isClose: true,
            },
            {
              title: ButtonTitle.ADD,
              onClickHandler: this._onAddButtonPopupClickHandler,
              isRequired: true,
              isClose: true,
            }
          ],
        }
      ]
    })
    .then((popupController) => {
      this._addPopupController = popupController;
    });
  }

  _getCaseContent() {
    this._caseController = new PeopleCaseController({
      nameType: this._nameType,
    });

    const aliases = deepCopyObject(this._people.aliases);

    return {
      type: ContentType.CUSTOM,
      content: {
        controller: this._caseController,
        name: `case`,
        value: aliases,
      }
    };
  }

  // _renderAddPopup2() {
  //   Popup.showCustom({
  //     size: PopupSize.SMALL,
  //     title: `Задать написание на другом языке`,
  //     onClosePopupHandler: () => {
  //       this._addPopupController = null;
  //     },
  //     contents: [
  //       this._getLanguageContent(),
  //       {
  //         type: ContentType.INPUT_NEW,
  //         content: {
  //           type: `text`,
  //           label: `Фамилия`,
  //           name: `surname`,
  //           value: ``,
  //           isRequired: true,
  //         }
  //       },
  //       {
  //         type: ContentType.TEXT,
  //         content: `<b>RU</b>: ${this._people.surname}`,
  //       },
  //       {
  //         type: ContentType.INPUT_NEW,
  //         content: {
  //           type: `text`,
  //           label: `Имя`,
  //           name: `lastname`,
  //           value: ``,
  //           // isRequired: true,
  //         }
  //       },
  //       {
  //         type: ContentType.TEXT,
  //         content: `<b>RU</b>: ${this._people.lastname}`,
  //       },
  //       {
  //         type: ContentType.INPUT_NEW,
  //         content: {
  //           type: `text`,
  //           label: `Отчество`,
  //           name: `middlename`,
  //           value: ``,
  //           // isRequired: true,
  //         }
  //       },
  //       {
  //         type: ContentType.TEXT,
  //         content: `<b>RU</b>: ${this._people.middlename}`,
  //       },
  //       {
  //         type: ContentType.BUTTONS,
  //         content: [
  //           {
  //             title: ButtonTitle.CANCEL,
  //             isClose: true,
  //           },
  //           {
  //             title: ButtonTitle.ADD,
  //             onClickHandler: this._onAddButtonPopupClickHandler,
  //             isRequired: true,
  //             isClose: true,
  //           }
  //         ],
  //       }
  //     ]
  //   })
  //   .then((popupController) => {
  //     this._addPopupController = popupController;
  //   });
  // }

  _setDataAliases(aliases) {
    if (this._nameType === NameType.SURNAME) {
      this._setDataSurnameAliases(aliases);
      return;
    }

    if (this._nameType === NameType.LASTNAME) {
      this._setDataLastnameAliases(aliases);
      return;
    }

    if (this._nameType === NameType.MIDDLENAME) {
      this._setDataMiddlenameAliases(aliases);
      return;
    }
  }

  _setDataSurnameAliases(aliases) {
    Promise.all(aliases.map((alias) => {
      return this._aliasAPI.setSurnamePeople(this._people.peopleId, alias.language, alias.title || ``);
    }))
    .then(() => this._onResolveRequest());
  }

  _setDataLastnameAliases(aliases) {
    Promise.all(aliases.map((alias) => {
      return this._aliasAPI.setLastnamePeople(this._people.peopleId, alias.language, alias.title || ``);
    }))
    .then(() => this._onResolveRequest());
  }

  _setDataMiddlenameAliases(aliases) {
    Promise.all(aliases.map((alias) => {
      return this._aliasAPI.setMiddlenamePeople(this._people.peopleId, alias.language, alias.title || ``);
    }))
    .then(() => this._onResolveRequest());
  }

  // _setDataAlias(alias) {
  //   Promise.all([
  //     this._aliasAPI.setSurnamePeople(this._people.peopleId, alias.language, alias.surname),
  //     this._aliasAPI.setLastnamePeople(this._people.peopleId, alias.language, alias.lastname),
  //     this._aliasAPI.setMiddlenamePeople(this._people.peopleId, alias.language, alias.middlename),
  //   ])
  //   .then(() => this._onResolveRequest(alias));
  // }

  _checkAliases(language) {
    const index = this.aliases.findIndex((alias) => alias.language === language);

    if (index === -1) {
      this._fillAlias({
        surname: ``,
        lastname: ``,
        middlename: ``,
      });

      return;
    }

    this._fillAlias(this.aliases[index]);
  }

  _fillAlias(alias) {
    this._addPopupController.inputs
      .filter((input) => input.name === `surname` || input.name === `lastname` || input.name === `middlename`)
      .forEach((input) => {
        input.value = alias[input.name];
      });
  }

  _onAddButtonPopupClickHandler() {
    const popupData = this._addPopupController.value;

    const caseData = popupData.case;

    const aliases = caseData.filter((data) => {
      const language = data.language;
      const it = this._people.aliases.find((alias) => alias.language === language);

      if (!it) {
        return true;
      }

      return it.title !== data.title;
    });

    this._setDataAliases(aliases);
  }
}
