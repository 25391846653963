import AbstractSmartComponent from '../abstract-smart-component.js';
import trashSvg from './../../../svg/trash.svg';

const createTrashSvgTemplate = () => {
  return (
    `<svg aria-hidden="true" width="25" height="30">
      <use class="" href="${trashSvg}"/>
    </svg>`
  );
};

export default class TrashSvgComponent extends AbstractSmartComponent {
  getTemplate() {
    return createTrashSvgTemplate();
  }
}
