import CopyLinkSvgComponent from "../../component/svg/copy-link";
import {render, RenderPosition} from "../../utils/render";
import ButtonController from "../button";

export default class CopyLinkButtonController extends ButtonController {
  constructor(parameters) {
    super(parameters);

    this._className = `master__btn master__btn--copyLinkSvg`;
    this._prompt = parameters.prompt || `Сохранить ссылку в буфер`;
  }

  render() {
    super.render();

    const svgComponent = new CopyLinkSvgComponent();
    render(this._button.getElement(), svgComponent.getElement(), RenderPosition.BEFOREEND);
  }
}
