import {isEmptyArray} from "../../utils/array/is-empty-array/is-empty-array";
import AbstractComponent from "../abstract-component";

const createAliasPeopleItemTemplate = (aliases, mode) => {
  if (isEmptyArray(aliases)) {
    return `<div></div>`;
  }

  const aliasesMarkup = aliases
    .map((alias) => {
      return (
        `<p class="master__peopleText"><b>${alias.language.toUpperCase()}</b>: ${alias[mode] || ``}</p>`
      );
    })
    .join(`\n`);

  return (
    `<div class="master__aliasPeopleWrap">
      ${aliasesMarkup}
    </div>`
  );
};

export default class AliasPeopleItemComponent extends AbstractComponent {
  constructor(mode = `surname`, aliases = []) {
    super();

    this._aliases = aliases;
    this._mode = mode;
  }

  getTemplate() {
    return createAliasPeopleItemTemplate(this._aliases, this._mode);
  }
}
