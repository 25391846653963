import CaseController from "./case";

export default class PeopleCaseController extends CaseController {
  constructor({nameType}) {
    super();

    this._nameType = nameType;
  }

  _getCaseTitle(caseName) {
    const caseItem = this._caseData.find((data) => data.language === caseName);

    return caseItem ? caseItem[this._nameType] : ``;
  }
}
