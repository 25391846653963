import {NTR} from "../../name-to-request";

export const ContestParameter = {
  ADDRESS: {[NTR.ADDRESS]: NTR.EMPTY},
  AFFIRMATION_DEADLINE: {[NTR.DEADLINE_AFFIRMATION]: NTR.EMPTY},
  AGE_CATEGORY: {[NTR.AGE_CATEGORY_TITLE]: NTR.EMPTY},

  CITIES: {[NTR.PERFORMANCE_CITY]: NTR.EMPTY},
  COMPERE: {[NTR.COMPERE]: NTR.EMPTY},
  CRITERIA: {[NTR.CRITERIA_TITLE]: NTR.EMPTY},
  CRITERIA_MAX_POINTS: {[NTR.CRITERIA_MAX_POINTS]: NTR.EMPTY},
  CRITERIA_DESCRIPTION: {[NTR.CRITERIA_NOTE]: NTR.EMPTY},
  CRITERIA_NOMINATION: {[NTR.CRITERIA_NOMINATION]: NTR.EMPTY},
  CRITERIA_WEIGHT: {[NTR.CRITERIA_WEIGHT]: NTR.EMPTY},

  DATE: {[NTR.DATE]: NTR.EMPTY},
  DIRECTOR: {[NTR.DIRECTOR]: NTR.EMPTY},
  DOCUMENT: {[NTR.CONTEST_DOCUMENT]: NTR.EMPTY},

  LANDMARK: {[NTR.LANDMARK_TITLE]: NTR.EMPTY},
  LANDMARK_START: {[NTR.LANDMARK_START]: NTR.EMPTY},

  MAIL_TEMPLATE: {[NTR.MAIL_TEMPLATE]: NTR.EMPTY},

  NOMINATION: {[NTR.NOMINATION_TITLE]: NTR.EMPTY},

  PAYMENT_DEADLINE: {[NTR.DEADLINE_PAYMENT]: NTR.EMPTY},
  PAYMENT_SYSTEM: {[NTR.PAYMENT_SYSTEM]: NTR.EMPTY},
  PERFORMANCE_COUNT: {[NTR.PERFORMANCE_COUNT]: NTR.EMPTY},
  PERFORMANCE_DURATION_ALL: {[NTR.PERFORMANCE_DURATION_SUM]: NTR.EMPTY},
  PERFORMANCE_PAUSE: {[NTR.PERFORMANCE_PAUSE]: NTR.EMPTY},
  PERFORMANCE_TIME_AVAIL: {[NTR.PERFORMANCE_TIME_AVAIL]: NTR.EMPTY},
  PLAYER: {[NTR.PLAYER]: NTR.EMPTY},
  PRICE_PER_PERFORMANCE: {[NTR.CONTEST_SERVICE_PRICE]: NTR.EMPTY},
  PRIZE: {[NTR.PRIZE_OPTION]: NTR.EMPTY},

  REFEREE: {[NTR.REFEREE]: NTR.EMPTY},
  REFEREE_FULL: {[NTR.REFEREE]: `surname,lastname,middlename,person,people_id`},
  // ROLE: {[NTR.ROLE]: ``},
  ROLE: {[NTR.ROLE]: `owner,administrator,soundman,referee,compere,hostess`},
  RULES_LINK: {[NTR.RULES_LINK]: NTR.EMPTY},

  SERVER_MAIL: {[NTR.SMTP]: NTR.EMPTY},
  SOUNDMAN: {[NTR.SOUNDMAN]: NTR.EMPTY},

  TARIFF: {[NTR.CONTEST_TARIFF]: NTR.EMPTY},
  TEAM_CITY: {[NTR.TEAM_CITY]: NTR.EMPTY},
  TEAM_TITLE: {[NTR.TEAM_TITLE]: NTR.EMPTY},
  TERMINAL: {[NTR.TERMINAL]: NTR.EMPTY},
  TIME_ZONE: {[NTR.TIME_ZONE]: NTR.EMPTY},

  TITLE: {[NTR.CONTEST_TITLE]: NTR.NULL},
  TITLE_SHORT: {[NTR.CONTEST_TITLE]: NTR.ONE},
  TITLE_LONG: {[NTR.CONTEST_TITLE]: NTR.TWO},

  QUALIFICATION: {[NTR.QUALIFICATION_TITLE]: NTR.EMPTY},
};

export const ALL_CONTEST_PARAMETERS = [
  ContestParameter.TITLE,
  ContestParameter.DATE,
  ContestParameter.TIME_ZONE,
  ContestParameter.ADDRESS,
  ContestParameter.RULES_LINK,
  ContestParameter.PERFORMANCE_COUNT,
  ContestParameter.PERFORMANCE_DURATION_ALL,
  ContestParameter.PERFORMANCE_TIME_AVAIL,

  ContestParameter.NOMINATION,
  ContestParameter.AGE_CATEGORY,
  ContestParameter.QUALIFICATION,
  ContestParameter.PRIZE,
  ContestParameter.DOCUMENT,
  ContestParameter.PLAYER,
  ContestParameter.DIRECTOR,
  ContestParameter.REFEREE,
  ContestParameter.CITIES,
  ContestParameter.LANDMARK,
  ContestParameter.LANDMARK_START,
  ContestParameter.CRITERIA,
  ContestParameter.CRITERIA_NOMINATION,
  ContestParameter.CRITERIA_DESCRIPTION,
  ContestParameter.CRITERIA_MAX_POINTS,
  ContestParameter.SERVER_MAIL,
  ContestParameter.TEAM_TITLE,
  ContestParameter.TEAM_CITY,
  ContestParameter.TARIFF,
  ContestParameter.PRICE_PER_PERFORMANCE,
  ContestParameter.PAYMENT_SYSTEM,

  ContestParameter.AFFIRMATION_DEADLINE,
  ContestParameter.PAYMENT_DEADLINE,
  ContestParameter.TERMINAL,
  ContestParameter.SOUNDMAN,
  ContestParameter.COMPERE,
];
