export const getFullNameText = (people, isShowMiddlename = true) => {
  const {surname, lastname, middlename} = people;

  const names = [];

  if (surname) {
    names.push(surname);
  }

  if (lastname) {
    names.push(lastname);
  }

  if (isShowMiddlename && middlename) {
    names.push(middlename);
  }

  return names.join(` `);
};
