import MultiInputsController from ".";
import PhoneInputComponent from "../../component/input/phone-input/phone-input";
import {ModeRenderWrap, MultiWrapInputRequiredMode} from "../../const";
import {isEmptyArray} from "../../utils/array/is-empty-array/is-empty-array";
import {render, RenderPosition} from "../../utils/render";

export default class PhoneMultiInputsController extends MultiInputsController {
  constructor({phones = [], isShowAddButton = false, inputMode = `passive`, isRequired = false, requiredMode = MultiWrapInputRequiredMode.NONE, modeRender = ModeRenderWrap.ALL, isDeleteButton = false, onChangeData = () => {}}) {
    super();

    this._phones = phones;
    this._isShowAddButton = isShowAddButton;
    this._addButtonTitle = `Добавить еще телефон`;
    this._label = `Телефон`;
    this._errorValidateText = `Заполните поле телефона`;

    this._isRequired = isRequired;
    this._inputMode = inputMode;
    this._requiredMode = requiredMode;
    this._modeRender = modeRender;
    this._isDeleteButton = isDeleteButton;
    this._onChangeData = onChangeData;
  }

  setInputHandler(handler) {
    this._handler = handler;
  }

  _renderInputs() {
    if (isEmptyArray(this._phones)) {
      const phoneInputComponent = this._getPhoneInput({
        label: this._label,
      });

      phoneInputComponent.setInputHandler(this._onInputHandler);
      phoneInputComponent.setDeleteButtonClickHandler(this._onDeleteHandler);
      this._inputComponents.push(phoneInputComponent);
      render(this._wrapComponent.getElement(), phoneInputComponent.getElement(), RenderPosition.BEFOREEND);
      return;
    }

    if (this._modeRender === ModeRenderWrap.ONE) {
      const phoneInputComponent = this._getPhoneInput({
        label: this._label,
        value: this._phones[0].phone,
        isDeleteButton: this._isDeleteButton,
      });
      phoneInputComponent.setInputHandler(this._onInputHandler);
      phoneInputComponent.setDeleteButtonClickHandler(this._onDeleteHandler);
      this._inputComponents.push(phoneInputComponent);
      render(this._wrapComponent.getElement(), phoneInputComponent.getElement(), RenderPosition.BEFOREEND);

      return;
    }

    this._phones.forEach((phone, i) => {
      const phoneInputComponent = this._getPhoneInput({
        label: `${this._label} #${i + 1}`,
        value: phone.phone,
        isDeleteButton: this._isDeleteButton,
      });

      phoneInputComponent.setInputHandler(this._onInputHandler);
      phoneInputComponent.setDeleteButtonClickHandler(this._onDeleteHandler);
      this._inputComponents.push(phoneInputComponent);
      render(this._wrapComponent.getElement(), phoneInputComponent.getElement(), RenderPosition.BEFOREEND);
    });
  }

  _renderEmptyInput() {
    const phoneInputComponent = this._getPhoneInput({
      label: `Еще телефон`,
      isDeleteButton: this._isDeleteButton,
    });
    phoneInputComponent.setInputHandler(this._onInputHandler);
    phoneInputComponent.setDeleteButtonClickHandler(this._onDeleteHandler);

    this._inputComponents.push(phoneInputComponent);
    render(this._wrapComponent.getElement(), phoneInputComponent.getElement(), RenderPosition.BEFOREEND);
  }

  _getPhoneInput({label, value = ``, isDeleteButton = false}) {
    const phoneInput = new PhoneInputComponent({
      label,
      isRequired: this._isRequired,
      value,
      isDeleteButton,
      mode: this._isRequired ? `active` : `passive`,
    });

    return phoneInput;
  }
}
